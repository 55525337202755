import { useEffect, useState, useRef } from "react";

function AppStyle({ options, title, onSelect }) {
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState("none");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const toggleDropdown = () => {
    setShow(!show);
  };
  const selectOption = (value) => {
    setSelectedValue(value);
    setShow(false);
    if (onSelect) onSelect(value); 
  };
  return (
    <div
      ref={dropdownRef}
      onClick={toggleDropdown}
      className={`dropdown ${selectedValue !== "none" ? "active" : ""}`}
    >
      {selectedValue !== "none" ? (
        <p className="blue-p-active" id="demo">
          {selectedValue}
          <i className="ri-arrow-drop-down-line"></i>
        </p>
      ) : (
        <p>
          {title}
          <i className="ri-arrow-drop-down-line"></i>
        </p>
      )}
      {show && (
        <div
          className={`dropdown-content ${
            title === "Style" || title === "Shape" ? "scroll" : ""
          }`}
        >
          {options.map((option) => (
            <p
              className={`${option} ${
                selectedValue === option ? "active" : ""
              }`}
              key={option}
              onClick={() => selectOption(option)}
            >
              {option}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M7.50052 11.3787L14.3948 4.48438L15.4555 5.54503L7.50052 13.5L2.72754 8.72704L3.7882 7.66639L7.50052 11.3787Z"
                  fill="#3F5DE5"
                />
              </svg>
            </p>
          ))}
          <div className="withe-gradient">
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AppStyle;
