import React, { useState, useEffect } from "react";
import * as actions from "./../../actions";
import { useDispatch } from "react-redux";
import { endPointAdress } from "../../config";
function AuthEmail({ switchToLogin, onInputChange, switchToCodeEmail }) {
  const [emailLogin, setEmailLogin] = useState("");

  const [isValidLogin, setIsValid] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  function closePopup() {
    document.querySelector(".popupError").style.display = "none";
  }
  const dispatch = useDispatch();
  const checkPaymentFalse = () => dispatch(actions.paymentFalse());

  useEffect(() => {
    const validate = () => {
      if (!emailLogin || !emailLogin.includes("@")) return false;
      return true;
    };
    setIsValid(validate());
  }, [emailLogin]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (isValidLogin) {
      handleSubmit();
    } else {
      console.log("Form is not valid");
    }
  };

  const handleSubmit = () => {
    function Abetka() {
      let inputErrorT = document.querySelector("#emailTrue");
      inputErrorT.classList.add("faili");
      inputErrorT.focus();
      setEmailLogin("");
    }
    fetch(`${endPointAdress}valid-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailLogin.toLowerCase(),
      }),
    })
      .then((response) => {
        console.log("Status code:", response.status);
        if (response.status === 200) {
          switchToCodeEmail();
        } else if (response.status === 409) {
          document.querySelector(".popupError").style.display = "flex";
          Abetka();
        } else if (response.status === 301) {
          fetch(`${endPointAdress}github-kids`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: emailLogin.toLowerCase(),
            }),
          })
            .then((response) => {
              console.log("Status code:", response.status);
              if (response.status === 200) {
                window.location.reload();
              } else if (response.status === 402) {
                checkPaymentFalse();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
      })
      .catch((error) => {});
  };

  return (
    <div className="auth__login pop">
      <div className="popupError">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="#FF5D52"
          />
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="url(#paint0_linear_2202_9790)"
            fillOpacity="0.7"
            style={{ mixBlendMode: "soft-light" }}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2202_9790"
              x1="2"
              y1="22"
              x2="22"
              y2="2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7F8FC" stopOpacity="0" />
              <stop offset="0.494206" stopColor="#F7F8FC" />
              <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p>
          Account with this email already exists. Try to{" "}
          <span onClick={switchToLogin}>Log In</span>.
        </p>
        <i onClick={closePopup} className="ri-close-line"></i>
      </div>
      <h1 className="auth__login-h1">Email Confirmation</h1>

      <div className="line__login"></div>
      <form onSubmit={handleSubmitLogin} className="auth__formLogin">
        <label className="auth__formLogin-label">
          <p style={{ color: isEmailFocused ? "#3F5DE5" : "#6E738E" }}>
            Email:<span> *</span>
          </p>
          <input
            id="emailTrue"
            className="auth__formLogin-input"
            type="email"
            value={emailLogin}
            onChange={(e) => {
              setEmailLogin(e.target.value);
              onInputChange(e.target.value, "email");
            }}
            placeholder="johndoe@mail.com"
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
          />
        </label>

        <button
          className="auth__formLogin-button"
          type="submit"
          disabled={!isValidLogin}
        >
          <p>Continue with email</p>
        </button>
      </form>

      <p className="auth__registerUser">
        Already have an account? <span onClick={switchToLogin}>Log In</span>
      </p>
    </div>
  );
}

export default AuthEmail;
