import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import NotificationsTab from "./tabSettings/tabNotifications";
import TabBilling from "./tabSettings/tabBilling";
import * as actions from "./../actions";
import { endPointAdress } from "./../config";
import deletePhoto from "./../img/delete_user.png";
import sendEmailPhoto from "./../img/email_send.png";
import Avvvatars from "avvvatars-react";
import CryptoJS from "crypto-js";
import Credits from "./appCredit";
let encryptionSeed = "385h52m27s81ms";

const AppSettings = React.memo(function AppSettings({ pricing }) {
  const stateUser = useSelector((state) => state, shallowEqual);
  const dispatch = useDispatch();
  const checkAuthFalse = () => dispatch(actions.authFalse());
  const userDataUpdate = () => dispatch(actions.userData());

  const [activeTab, setActiveTab] = useState(0);
  const [noActive, setNoActive] = useState(0);
  const [rerenders, setRerenders] = useState("");
  const [password, setPassword] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [passwordOld, setPasswordOld] = useState("");
  const [isValidLogin, setIsValid] = useState(false);
  const [isValidNew, setIsValidNew] = useState(false);
  const [condition, setCondition] = useState(false);
  const [avatar, setAvatar] = useState(stateUser.avatar);
  const [nickname, setNickname] = useState(stateUser.nickname);
  const [email, setEmail] = useState(stateUser.email);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formattedDate, setFormattedDate] = useState("");
  const [currentPlanStatus, setCurrentPlanStatus] = useState(
    localStorage.getItem("status")
  );
  
// скидання всіх помилок інпутів
  const inputErrorEmail = document.querySelector("#inputChangeEmail");
  const error409 = document.querySelector("#email409");
  const inputErrorPassword = document.querySelector("#inputChangePassword");
  const error403 = document.querySelector("#password403");
  const inputErrorNickname = document.querySelector("#inputChangeNickname");
  const errorNickname = document.querySelector("#nicknameError");
  const resetError = () => {
    error409.classList.remove("true");
    inputErrorEmail.classList.remove("faili");
    inputErrorPassword.classList.remove("faili");
    error403.classList.remove("true");
    errorNickname.classList.remove("true");
    inputErrorNickname.classList.remove("faili");
  }

  useEffect(() => {
    setAvatar(stateUser.avatar);
    setNickname(stateUser.nickname);
    setEmail(stateUser.email);
    if (stateUser.avatar !== "basic") {
      setCondition(true);
    } else if (stateUser.avatar === "basic") {
      setCondition(false);
    }
  }, [stateUser.avatar, stateUser.nickname, stateUser.email]);
  useEffect(() => {
    if (document.querySelector("#inputConfirmPassword") !== null) {
      if (email !== stateUser.email) {
        document.querySelector("#inputConfirmPassword").style.display = "flex";
      } else if (email === stateUser.email) {
        document.querySelector("#inputConfirmPassword").style.display = "none";
      }
    }
  }, [email]);
  useEffect(() => {
    const validate = () => {
      if (nickname !== stateUser.nickname && email === stateUser.email) {
        return true;
      } else if (email !== stateUser.email && password.length >= 8) {
        return true;
      } else {
        return false;
      }
    };
    setIsValid(validate());
  }, [password, nickname]);
  useEffect(() => {
    const handleStorageChange = (e) => {
      console.log("Storage event triggered for key:", e.key);
      if (e.key === "nick") {
        setNickname(e.newValue);
      }
      if (e.key === "email") {
        setEmail(e.newValue);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [nickname, email]);
  useEffect(() => {
    if (condition) {
      document.getElementById("userAvaProfile").src = avatar;
    }
  }, [condition, avatar]);
  useEffect(() => {
    const validateR = () => {
      if (!passwordOld || passwordOld.length < 8) return false;
      if (!passwordReset || passwordReset.length < 8) return false;
      return true;
    };
    setIsValidNew(validateR());
  }, [passwordOld, passwordReset]);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setAvatar(reader.result);
    };
    if (file.size > 1 * 1024 * 1024) {
      // Перевірка, чи розмір файлу менше 2 МБ
      if (document.querySelector("#overSize")) {
        document.querySelector("#overSize").style.display = "flex";
      }
    } else {
      if (file) {
        reader.readAsDataURL(file);
        const formData = new FormData();
        formData.append("file", file, file.name);

        fetch(`${endPointAdress}upload-avatar`, {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            localStorage.setItem(
              "gammaRef",
              CryptoJS.AES.encrypt(data.ava, encryptionSeed).toString()
            );
            userDataUpdate();
            setAvatar(stateUser.avatar);
            resetError();
          })
          .catch((error) => {
            console.error("Помилка при відправці фотографії:", error);
          });
      } else {
        console.error("Фотографію не вибрано!");
      }
    }
  };

  const funcCallDeleteUser = () => {
    fetch(`${endPointAdress}delete-user`, {
      method: "DELETE",
    });
    checkAuthFalse();
  };

  const toggleEmailPopup = () => {
    const popupEmail = document.querySelector(".popup__email");
    if (popupEmail.style.display === "none") {
      popupEmail.style.display = "flex";
      setTimeout(() => {
        popupEmail.style.opacity = "1";
      }, 50);
    } else {
      popupEmail.style.opacity = "0";
      setTimeout(() => {
        popupEmail.style.display = "none";
      }, 300);
    }
  };

  const emailRef = useRef(null);
  const emailBgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const popupEmail = document.querySelector(".popup__email");
      if (
        emailBgRef.current.contains(event.target) &&
        !emailRef.current.contains(event.target)
      ) {
        popupEmail.style.opacity = "0";
        setTimeout(() => {
          popupEmail.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleDeletePopup = () => {
    const popupDelete = document.querySelector(".popup__delete");
    if (popupDelete.style.display === "none") {
      popupDelete.style.display = "flex";
      setTimeout(() => {
        popupDelete.style.opacity = "1";
      }, 50);
    } else {
      popupDelete.style.opacity = "0";
      setTimeout(() => {
        popupDelete.style.display = "none";
      }, 300);
    }
  };
  const deleteRef = useRef(null);
  const deleteBgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const popupDelete = document.querySelector(".popup__delete");
      if (
        deleteBgRef.current.contains(event.target) &&
        !deleteRef.current.contains(event.target)
      ) {
        popupDelete.style.opacity = "0";
        setTimeout(() => {
          popupDelete.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const togglePasswordPopup = () => {
    const PasswordPopup = document.querySelector("#popup__Password");
    if (PasswordPopup.style.display === "none") {
      PasswordPopup.style.display = "flex";
      setTimeout(() => {
        PasswordPopup.style.opacity = "1";
      }, 50);
    } else {
      PasswordPopup.style.opacity = "0";
      setTimeout(() => {
        PasswordPopup.style.display = "none";
      }, 300);
    }
  };
  const passwirdBgRef = useRef(null);
  const passwirdRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const PasswordPopup = document.querySelector("#popup__Password");
      if (
        passwirdBgRef.current.contains(event.target) &&
        !passwirdRef.current.contains(event.target)
      ) {
        PasswordPopup.style.opacity = "0";
        setTimeout(() => {
          PasswordPopup.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const nickBgRef = useRef(null);
  const nickRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const nickPopup = document.querySelector("#popup__nick");
      if (
        nickBgRef.current.contains(event.target) &&
        !nickRef.current.contains(event.target)
      ) {
        nickPopup.style.opacity = "0";
        setTimeout(() => {
          nickPopup.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const toggleNicknamePopup = () => {
    const PasswordPopup = document.querySelector("#popup__nick");
    if (PasswordPopup.style.display === "none") {
      PasswordPopup.style.display = "flex";
      setTimeout(() => {
        PasswordPopup.style.opacity = "1";
      }, 50);
    } else {
      PasswordPopup.style.opacity = "0";
      setTimeout(() => {
        PasswordPopup.style.display = "none";
      }, 300);
    }
  };
  const resetInputEdit = () => {
    resetError();
    setNickname(stateUser.nickname);
    setEmail(stateUser.email);
    setPassword("");
  };
  const changeUserInfo = () => {
    
    function Error409() {
      resetError();
      error409.classList.add("true");
      inputErrorEmail.classList.add("faili");
      inputErrorEmail.focus();
    }
    function Error403() {
      resetError();
      inputErrorPassword.classList.add("faili");
      error403.classList.add("true");
      inputErrorPassword.focus();
    }
    const regex = /^[A-Za-z0-9_-]+$/;
    
    function ErrorNickname() {
      resetError();
      errorNickname.classList.add("true");
      inputErrorNickname.classList.add("faili");
      inputErrorNickname.focus();
    }
    if (!regex.test(nickname)) {
      ErrorNickname();
    } else {
      fetch(`${endPointAdress}change-user-info`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: nickname,
          email: email.toLocaleLowerCase(),
          password: password,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setPassword("");
            resetError();
            return response.json();
          } else if (response.status === 409) {
            Error409();
          } else if (response.status === 500) {
            console.log(response.text());
          } else if (response.status === 403) {
            Error403();
          } else if (response.status === 412) {
            Error409();
          } else if (response.status === 429) {
            if (document.querySelector("#too-many-nickname")) {
              document.querySelector("#too-many-nickname").style.display =
                "flex";
            }
          }
        })
        .then((data) => {
          console.log(data.email);
          console.log(email.toLocaleLowerCase());
          let decryptedBetaBytes = CryptoJS.AES.decrypt(
            localStorage.getItem("betaID"),
            encryptionSeed
          );
          let decryptedEmail = decryptedBetaBytes.toString(CryptoJS.enc.Utf8);
          if (data.email !== decryptedEmail) {
            toggleEmailPopup();
          } else {
            toggleNicknamePopup();
          }
          localStorage.setItem(
            "alphaID",
            CryptoJS.AES.encrypt(data.username, encryptionSeed).toString()
          );
        });
    }
  };
  const deleteAvatarUser = () => {
    fetch(`${endPointAdress}upload-avatar`, {
      method: "DELETE",
    })
      .then((response) => {
        console.log(response.status);
        if (response.status === 200) {
          localStorage.setItem(
            "gammaRef",
            CryptoJS.AES.encrypt("basic", encryptionSeed).toString()
          );
          userDataUpdate();
          setAvatar(stateUser.avatar);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const sendNewPassword = () => {
    let inputErrorPasswordOld = document.querySelector("#passwordOld");
    let error409 = document.querySelector("#password409");
    let inputErrorPasswordNew = document.querySelector("#passwordNew");
    let error404 = document.querySelector("#password404");
    let passwordNotTrue = document.querySelector('#passwordNotTrue');
    const regex = /^[A-Za-z\d.,!@#$%^&*]+$/;
    function resetError() {
      error409.classList.remove("true");
      inputErrorPasswordOld.classList.remove("faili");
      inputErrorPasswordNew.classList.remove("faili");
      error404.classList.remove("true");
      passwordNotTrue.classList.remove("true");
    }
    function Error409() {
      resetError();
      error409.classList.add("true");
      inputErrorPasswordNew.classList.add("faili");
      inputErrorPasswordNew.focus();
    }
    function Error404() {
      resetError();
      inputErrorPasswordOld.classList.add("faili");
      error404.classList.add("true");
      inputErrorPasswordOld.focus();
    }
    function ErrorNotTrue() {
      resetError();
      passwordNotTrue.classList.add("true");
      inputErrorPasswordNew.classList.add("faili");
      inputErrorPasswordNew.focus();
    }
    if (regex.test(passwordReset)) {
      fetch(`${endPointAdress}change-password`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          old: passwordOld,
          new: passwordReset,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            togglePasswordPopup();
            setPasswordOld("");
            setPasswordReset("");
            resetError();
          } else if (response.status === 409) {
            Error409();
            setPasswordOld("");
            setPasswordReset("");
          } else if (response.status === 403) {
            Error404();
            setPasswordOld("");
            setPasswordReset("");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      ErrorNotTrue();
    }
    
  };

  const closePopup = () => {
    document.querySelector("#overSize").style.display = "none";
  };
  const closePopupNick = () => {
    document.querySelector("#too-many-nickname").style.display = "none";
  };

  useEffect(() => {
    console.log(formattedDate);
    setRerenders(
      <TabBilling
        date={formattedDate}
        planStatus={currentPlanStatus}
        pricing={pricing}
        setCurrentPlanStatus={() => setCurrentPlanStatus()}
        setFormattedDate={() => setFormattedDate()}
      />
    );
  }, [stateUser.plan, stateUser.year, formattedDate, stateUser.formattedDate, currentPlanStatus]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      style={
        activeTab === 0
          ? {
              height: "1467px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }
          : {
              height: "990px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }
      }
    >
      <div className="app__settings">
        {windowWidth > 768 ? <Credits pricing={pricing} /> : null}
        <h2 className="app_h2">Hello, {nickname}</h2>
        <div className="tabs-containers">
          <div className="tabs-headers">
            <button
              className={activeTab === 0 ? "active" : ""}
              onClick={() => setActiveTab(0)}
            >
              profile
            </button>
            <button
              className={activeTab === 1 ? "active" : ""}
              onClick={() => setActiveTab(1)}
            >
              notifications
            </button>
            <button
              id="tabPlan&Billing"
              className={activeTab === 2 ? "active" : ""}
              onClick={() => {
                setActiveTab(2);
              }}
            >
              Plan & Billing
            </button>
          </div>
          <div className="tabs-contents">
            <div className={`tab-panels ${activeTab === 0 ? "active" : ""}`}>
              <div className="app__setings-profile">
                <div className="app_setings-profile-form">
                  <div className="avatar-section">
                    {condition ? (
                      <img id="userAvaProfile" alt="User Avatar" />
                    ) : (
                      <Avvvatars size={112} value={nickname} style="shape" />
                    )}

                    <div className="custom-file-input">
                      <input
                        type="file"
                        id="realFileInput"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                        autoComplete="file"
                      />
                      <div
                        className="button-avatar"
                        onClick={() =>
                          document.getElementById("realFileInput").click()
                        }
                      >
                        <i className="ri-add-line"></i>
                      </div>
                      <button
                        className="button-avatar"
                        onClick={deleteAvatarUser}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </button>
                    </div>
                  </div>
                  <div className="input-edit" style={{ marginBottom: "24px" }}>
                    <label>Nickname</label>
                    <input
                      id="inputChangeNickname"
                      className="auth__formLogin-input"
                      type="text"
                      value={nickname}
                      onChange={(e) =>
                        setNickname(e.target.value.replace(/\s/g, ""))
                      }
                      autoComplete="text"
                    />
                    <p id="nicknameError" className="error-p">
                      <i className="ri-information-line"></i>Usernames must be
                      written in Latin script. Please avoid using Cyrillic or
                      other non-standard characters.
                    </p>
                  </div>
                  <div
                    ref={nickBgRef}
                    id="popup__nick"
                    className="popup__password"
                    style={{ style: "opacity: 0", display: "none" }}
                  >
                    <div ref={nickRef} className="popup__password-window">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                          fill="url(#paint0_linear_2676_16067)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_2676_16067"
                            x1="20.6073"
                            y1="11.9889"
                            x2="3.63671"
                            y2="11.9889"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#415EED" />
                            <stop offset="1" stopColor="#3651DA" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <h4>Nickname changed</h4>
                      <button
                        className="cancel-delete"
                        onClick={() => toggleNicknamePopup()}
                      >
                        <i className="ri-close-line"></i>
                      </button>
                    </div>
                  </div>
                  <div className="input-edit" style={{ marginBottom: "24px" }}>
                    <label>Email</label>
                    <input
                      className="auth__formLogin-input"
                      id="inputChangeEmail"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      disabled={stateUser.Auth === "oauth"}
                    />
                    <p id="email409" className="error-p">
                      <i className="ri-information-line"></i>Account with this
                      email already exists.
                    </p>
                  </div>
                  <div
                    id="inputConfirmPassword"
                    className="input-edit"
                    style={{ display: "none" }}
                  >
                    <label>Password (If changing email)</label>
                    <input
                      id="inputChangePassword"
                      className="auth__formLogin-input"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="********"
                      autoComplete="password"
                    />
                    <p id="password403" className="error-p">
                      <i className="ri-information-line"></i>Password doesn’t
                      match
                    </p>
                  </div>
                  <div className="container-edit-button">
                    <button
                      className="save-edits"
                      disabled={!isValidLogin}
                      onClick={() => changeUserInfo()}
                    >
                      <p>Save Edits</p>
                    </button>
                    <button
                      className="cancel-edits"
                      onClick={() => resetInputEdit()}
                      disabled={!isValidLogin}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="line-h"></div>
                  <div className="input-edit">
                    <label
                      className={`act ${
                        noActive === 1 ? "noActive" : "active"
                      }`}
                    >
                      Password
                    </label>

                    <div
                      className={`auth__formLogin-input ${
                        noActive === 1 ? "noActive" : "active"
                      }`}
                      style={{ color: "#bcc1d1" }}
                    >
                      ********
                    </div>
                    <p
                      style={
                        stateUser.Auth !== "oauth" ? { display: "none" } : {}
                      }
                      className="error-oauth"
                    >
                      <i className="ri-information-line"></i>You can’t change
                      password since you have authorized via side service
                    </p>
                    <button
                      disabled={stateUser.Auth === "oauth"}
                      className={`resend-password ${
                        noActive === 1 ? "noActive" : "active"
                      }`}
                      onClick={() => setNoActive(1)}
                    >
                      Change Password
                    </button>
                    <label
                      className={`act ${
                        noActive === 1 ? "active" : "noActive"
                      }`}
                    >
                      Old Password
                    </label>
                    <input
                      className={`auth__formLogin-input ${
                        noActive === 1 ? "active" : "noActive"
                      }`}
                      id="passwordOld"
                      type="password"
                      value={passwordOld}
                      onChange={(e) => setPasswordOld(e.target.value)}
                      placeholder="********"
                      autoComplete="password"
                    />
                    <p id="password404" className="error-p">
                      <i className="ri-information-line"></i>Incorrect password
                    </p>
                    <label
                      className={`act ${
                        noActive === 1 ? "active" : "noActive"
                      }`}
                      style={{ marginTop: "48px" }}
                    >
                      New Password
                    </label>
                    <input
                      className={`auth__formLogin-input ${
                        noActive === 1 ? "active" : "noActive"
                      }`}
                      id="passwordNew"
                      type="password"
                      value={passwordReset}
                      onChange={(e) => setPasswordReset(e.target.value)}
                      placeholder="********"
                      autoComplete="password"
                    />
                    <p id="password409" className="error-p">
                      <i className="ri-information-line"></i>The password is the
                      same as the old one
                    </p>
                    <p id="passwordNotTrue" className="error-p">
                      <i className="ri-information-line"></i>Only letters, numbers and
            common punctuation symbols are allowed
                    </p>
                    <button
                      className={`send-resend-password ${
                        noActive === 1 ? "active" : "noActive"
                      }`}
                      onClick={() => sendNewPassword()}
                      style={{ marginTop: "48px" }}
                      disabled={!isValidNew}
                    >
                      <p>Save Edits</p>
                    </button>
                    {/* <p id='fail1' className="fail"><i className="ri-information-line"></i>Only letters, numbers and common punctuation symbols are allowed</p> */}
                  </div>
                  <div className="line-h"></div>
                  <div className="userButton">
                    <button
                      className="logOut-user"
                      onClick={() => checkAuthFalse()}
                    >
                      <i className="ri-logout-box-line"></i> Log Out
                    </button>
                    <button
                      className="delete-user"
                      onClick={() => toggleDeletePopup()}
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`tab-panels ${activeTab === 1 ? "active" : ""}`}>
              <NotificationsTab />
            </div>
            <div className={`tab-panels ${activeTab === 2 ? "active" : ""}`}>
              {rerenders}
            </div>
            <div
              ref={deleteBgRef}
              className="popup__delete"
              style={{ style: "opacity: 0", display: "none" }}
            >
              <div ref={deleteRef} className="popup__delete-window">
                <img src={deletePhoto} alt="delete" />
                <h4>Delete account?</h4>
                <p>Are you sure you want to permanently delete your account?</p>
                <button
                  className="yes-delete"
                  onClick={() => funcCallDeleteUser()}
                >
                  Yes, delete my account
                </button>
                <button
                  className="cancel-delete"
                  onClick={() => toggleDeletePopup()}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div
              ref={passwirdBgRef}
              id="popup__Password"
              className="popup__password"
              style={{ style: "opacity: 0", display: "none" }}
            >
              <div ref={passwirdRef} className="popup__password-window">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M10.0007 15.1709L19.1931 5.97852L20.6073 7.39273L10.0007 17.9993L3.63672 11.6354L5.05093 10.2212L10.0007 15.1709Z"
                    fill="url(#paint0_linear_2676_16067)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2676_16067"
                      x1="20.6073"
                      y1="11.9889"
                      x2="3.63671"
                      y2="11.9889"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                  </defs>
                </svg>
                <h4>Password changed</h4>
                <button
                  className="cancel-delete"
                  onClick={() => togglePasswordPopup()}
                >
                  <i className="ri-close-line"></i>
                </button>
              </div>
            </div>
            <div
              ref={emailBgRef}
              className="popup__email"
              style={{ style: "opacity: 0", display: "none" }}
            >
              <div ref={emailRef} className="popup__email-window">
                <img src={sendEmailPhoto} alt="send email" />
                <h4>Email change</h4>
                <p>
                  Check <span>{email}</span> and follow instructions to confirm
                  your new email address.
                </p>
                <button
                  className="cancel-email"
                  onClick={() => toggleEmailPopup()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="overSize" className="popupError settings">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="#FF5D52"
          />
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="url(#paint0_linear_2202_9790)"
            fillOpacity="0.7"
            style={{ mixBlendMode: "soft-light" }}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2202_9790"
              x1="2"
              y1="22"
              x2="22"
              y2="2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7F8FC" stopOpacity="0" />
              <stop offset="0.494206" stopColor="#F7F8FC" />
              <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p>The user's photo should not weigh more than 4 MB.</p>
        <i onClick={closePopup} className="ri-close-line"></i>
      </div>
      <div id="too-many-nickname" className="popupError settings">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="#FF5D52"
          />
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="url(#paint0_linear_2202_9790)"
            fillOpacity="0.7"
            style={{ mixBlendMode: "soft-light" }}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2202_9790"
              x1="2"
              y1="22"
              x2="22"
              y2="2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7F8FC" stopOpacity="0" />
              <stop offset="0.494206" stopColor="#F7F8FC" />
              <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p>
          You have changed your nickname quite frequently. Please wait a bit
          before trying to change it again.
        </p>
        <i onClick={closePopupNick} className="ri-close-line"></i>
      </div>
    </div>
  );
});

export default AppSettings;
