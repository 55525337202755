import React, { useState, useEffect } from "react";

import { endPointAdress } from "../../config";
function ForgotPass({ switchToLogin, onInputChange, switchToSend }) {
  const [emailLogin, setEmailLogin] = useState("");

  const [isValidLogin, setIsValid] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);

  useEffect(() => {
    const validate = () => {
      if (!emailLogin || !emailLogin.includes("@")) return false;
      return true;
    };
    setIsValid(validate());
  }, [emailLogin]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (isValidLogin) {
      console.log("Submitting login form with email and password");
      // серв
    } else {
      console.log("Form is not valid");
    }
  };

  useEffect(() => {
    document.querySelector("#mainRoot").className = "";
  }, []);

  const handleSubmit = () => {
    fetch(`${endPointAdress}forgot-pass`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: emailLogin.toLowerCase(),
      }),
    })
      .then((response) => {
        console.log("Status code:", response.status);
        if (response.status === 200) {
          switchToSend();
        } else {
          document.querySelector("#fail1").classList.add("true");
          let inputErrorT = document.querySelector("#nickTrue");
          inputErrorT.classList.add("faili");
          inputErrorT.focus();
          setEmailLogin("");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="auth__login">
      <h1 className="auth__login-h1 forgot">Restore password</h1>
      <p className="forgot__password t">
        We will send you instructions for <br />
        password restoring on your email.
      </p>

      <form onSubmit={handleSubmitLogin} className="auth__formLogin">
        <label className="auth__formLogin-label">
          <p style={{ color: isEmailFocused ? "#3F5DE5" : "#6E738E" }}>
            Email:<span> *</span>
          </p>
          <input
            id="nickTrue"
            className="auth__formLogin-input"
            type="email"
            value={emailLogin}
            onChange={(e) => {
              setEmailLogin(e.target.value);
              onInputChange(e.target.value, "email");
            }}
            placeholder="johndoe@mail.com"
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
          />
          <p id="fail1" className="fail">
            <i className="ri-information-line"></i>This email doesn’t exist
          </p>
        </label>

        <button
          className="auth__formLogin-button Reg"
          onClick={handleSubmit}
          type="submit"
          disabled={!isValidLogin}
        >
          <p>Restore</p>
        </button>
      </form>

      <button className="auth__forgotUser" onClick={switchToLogin}>
        <i class="ri-close-line"></i>
      </button>
    </div>
  );
}

export default ForgotPass;
