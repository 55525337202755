import React, { useState, useEffect, useCallback } from "react";
import { endPointAdress } from "../../config";

function RegStep1({
  switchToLogin,
  switchLogo,
  inputValue,
  switchToStep2,
  switchToReg,
}) {
  console.log("Email: ", inputValue);

  let [codeValue0, setCodeValue0] = useState("");
  let [codeValue1, setCodeValue1] = useState("");
  let [codeValue2, setCodeValue2] = useState("");
  let [codeValue3, setCodeValue3] = useState("");
  let [codeValue4, setCodeValue4] = useState("");
  let [codeValue5, setCodeValue5] = useState("");
  const [isValidLogin, setIsValid] = useState(false);
  const numInputs = 6;
  const refs = Array.from({ length: numInputs }).map(() => React.createRef());
  let [codeInputs, setCodeInputs] = React.useState(Array(numInputs).fill(""));
  const focusNextInput = (currentInputIndex) => {
    if (currentInputIndex < numInputs - 1) {
      refs[currentInputIndex + 1].current.focus();
    }
  };
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(30);
  const handleClick = () => {
    setButtonDisabled(true);
    fetch(`${endPointAdress}mail-resend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputValue.email.toLowerCase(),
      }),
    })
      .then((response) => {
        console.log("Status code:", response.status);
        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    let timerCount = timer;
    const interval = setInterval(() => {
      timerCount--;
      setTimer(timerCount);
      if (timerCount === 0) clearInterval(interval);
    }, 1000);

    setTimeout(() => {
      setButtonDisabled(false);
      setTimer(30);
      clearInterval(interval);
    }, 30000);
  };
  useEffect(() => {
    document.querySelector("#mainRoot").className = "";
  }, []);
  const submitCode = useCallback(() => {
    let verificationCode = codeInputs.join("");
    console.log(verificationCode);
    if (verificationCode.length === 6) {
      fetch(`${endPointAdress}mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: verificationCode,
          email: inputValue.email.toLowerCase(),
        }),
      })
        .then((response) => {
          console.log("Status code:", response.status);
          if (response.status === 200) {
            if (
              localStorage.getItem("origin") &&
              localStorage.getItem("SID") &&
              localStorage.getItem("HSID")
            ) {
              fetch(`${endPointAdress}github-kids`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email: inputValue.email.toLowerCase(),
                  origin: localStorage.getItem("origin"),
                  SID: localStorage.getItem("SID"),
                  HSID: localStorage.getItem("HSID"),
                }),
              })
                .then((response) => {
                  console.log("Status code:", response.status);
                  console.log(response.text());
                  return response.json();
                })
                .then((data) => {
                  console.log("Response data:", data);

                  window.location.reload();
                })
                .catch((error) => {
                  console.error("Error:", error);
                });
            } else {
              switchToStep2();
            }
          } else {
            verificationCode = "";
            setCodeInputs("");
            setCodeValue0("");
            setCodeValue1("");
            setCodeValue2("");
            setCodeValue3("");
            setCodeValue4("");
            setCodeValue5("");
            const codeInputField =
              document.querySelectorAll(".code-input-field");
            codeInputField[0].focus();
            codeInputField.forEach((itemInput) => {
              itemInput.classList.add("faili");
            });
            document.querySelector("#fail2").classList.add("true");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Response data:", data);
        })
        .catch((error) => {});
    }
  }, [codeInputs, switchToStep2, inputValue]);

  const handleInputChange = (e, idx) => {
    if (!e.target.value) return;
    const newCodeInputs = [...codeInputs];
    newCodeInputs[idx] = e.target.value;
    setCodeInputs(newCodeInputs);
    focusNextInput(idx);
  };
  const handlePaste = async (e) => {
    let pastedData;
    if (e.type === "paste") {
      e.preventDefault();
      pastedData = e.clipboardData
        ? e.clipboardData.getData("text")
        : window.clipboardData.getData("Text");
    } else if (e.type === "click") {
      if (navigator.clipboard) {
        pastedData = await navigator.clipboard.readText();
      } else {
        console.log("Access to clipboard is denied.");
        return;
      }
    }
    e.preventDefault();
    const pastedChars = pastedData.split("").slice(0, numInputs); // Обмежуємо максимальну довжину рядка до numInputs символів
    let arreyFull = [];
    pastedChars.forEach((char, i) => {
      if (refs[i] && char) {
        refs[i].current.value = char;
        arreyFull[i] = char; // Зберігаємо отримані символи в масиві arreyFull
      }
    });
    pastedData.split("").forEach((char, i) => {
      if (refs[i]) {
        refs[i].current.value = char;
        if (i === 0) {
          setCodeValue0(char);
          arreyFull[0] = char;
        } else if (i === 1) {
          setCodeValue1(char);
          arreyFull[1] = char;
        } else if (i === 2) {
          setCodeValue2(char);
          arreyFull[2] = char;
        } else if (i === 3) {
          setCodeValue3(char);
          arreyFull[3] = char;
        } else if (i === 4) {
          setCodeValue4(char);
          arreyFull[4] = char;
        } else if (i === 5) {
          setCodeValue5(char);
          arreyFull[5] = char;
          return setCodeInputs(arreyFull);
        }
      }
    });

    const lastCharIndex = Math.min(pastedData.length, numInputs) - 1;

    if (refs[lastCharIndex]) {
      refs[lastCharIndex].current.focus();
    }
    const verificationCode = codeInputs;
    console.log(verificationCode);
  };

  useEffect(() => {
    if (!codeInputs.includes("")) {
      // Check if all inputs are filled
      submitCode();
    }
  }, [codeInputs, submitCode]);

  useEffect(() => {
    switchLogo();

    const validate = () => {
      if (document.querySelectorAll(".code-input-field")[0].value === "")
        return false;
      return true;
    };
    setIsValid(validate());
  }, [
    codeValue0,
    codeValue1,
    codeValue2,
    codeValue3,
    codeValue4,
    codeValue5,
    switchLogo,
    inputValue,
  ]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    document.querySelector("#mainRoot").className = "";
  }, []);

  return (
    <div className="auth__login">
      <h1 className="auth__login-h1">Confirm your mail</h1>
      <p className="auth__login-p mobile">
        We have sent a 6-digit code on <span>{inputValue.email}</span>.<br />{" "}
        Paste it here to continue registration.
      </p>

      <form onSubmit={handleSubmitLogin} className="auth__formLogin">
        <label className="auth__formLogin-label">
          <p>
            Code from email{" "}
            <span className="switchToForgot" onClick={handlePaste}>
              Paste<i className="ri-clipboard-line"></i>
            </span>
          </p>
          <div className="code-input-container" onPaste={handlePaste}>
            <input
              key={0}
              ref={refs[0]}
              value={codeValue0}
              maxLength="1"
              onChange={(e) => {
                handleInputChange(e, 0);
                setCodeValue0(e.target.value);
              }}
              className="code-input-field"
              placeholder="X"
            />
            <input
              key={1}
              ref={refs[1]}
              value={codeValue1}
              maxLength="1"
              onChange={(e) => {
                handleInputChange(e, 1);
                setCodeValue1(e.target.value);
              }}
              className="code-input-field"
              placeholder="X"
            />

            <input
              key={2}
              ref={refs[2]}
              value={codeValue2}
              maxLength="1"
              onChange={(e) => {
                handleInputChange(e, 2);
                setCodeValue2(e.target.value);
              }}
              className="code-input-field"
              placeholder="X"
            />

            <input
              key={3}
              ref={refs[3]}
              value={codeValue3}
              maxLength="1"
              onChange={(e) => {
                handleInputChange(e, 3);
                setCodeValue3(e.target.value);
              }}
              className="code-input-field"
              placeholder="X"
            />

            <input
              key={4}
              ref={refs[4]}
              value={codeValue4}
              maxLength="1"
              onChange={(e) => {
                handleInputChange(e, 4);
                setCodeValue4(e.target.value);
              }}
              className="code-input-field"
              placeholder="X"
            />

            <input
              key={5}
              ref={refs[5]}
              value={codeValue5}
              maxLength="1"
              onChange={(e) => {
                handleInputChange(e, 5);
                setCodeValue5(e.target.value);
              }}
              className="code-input-field"
              placeholder="X"
            />
          </div>
          <p id="fail2" className="fail">
            <i className="ri-information-line"></i>Wrong code
          </p>
        </label>
      </form>
      <div className="container-button">
        <button className="change-resend" onClick={switchToReg} type="submit">
          <p>Change Email</p>
        </button>
        <button
          className="change-resend"
          onClick={handleClick}
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? `Resend Code ${timer}` : "Resend Code"}
        </button>
      </div>
      <button className="auth__forgotUser" onClick={switchToLogin}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );
}

export default RegStep1;
