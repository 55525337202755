import { useSelector } from "react-redux";

function BasicPlanM({currency, price}) {
  const stateUser = useSelector((state) => state);
  return (
    <div className="current-plan">
      <div className="current-plan-top">
        <p className="plan">
          {stateUser.plan === "Basic" ? "SWITCHING TO" : "DownGRADING to"}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M6.07478 18.9432C5.55479 20.7265 5.29479 21.6182 4.79475 21.6182C4.29472 21.6182 4.03472 20.7265 3.51473 18.9432L3.41126 18.5884L3.05643 18.4849C1.27314 17.9649 0.3815 17.7049 0.3815 17.2049C0.3815 16.7049 1.27314 16.4449 3.05643 15.9249L3.41126 15.8214L3.51473 15.4666C4.03472 13.6833 4.29472 12.7917 4.79475 12.7917C5.29479 12.7917 5.55479 13.6833 6.07478 15.4666L6.17825 15.8214L6.53308 15.9249C8.31637 16.4449 9.20801 16.7049 9.20801 17.2049C9.20801 17.7049 8.31637 17.9649 6.53308 18.4849L6.17825 18.5884L6.07478 18.9432Z"
            fill="url(#paint0_linear_198_13514)"
          />
          <path
            d="M6.07478 18.9432C5.55479 20.7265 5.29479 21.6182 4.79475 21.6182C4.29472 21.6182 4.03472 20.7265 3.51473 18.9432L3.41126 18.5884L3.05643 18.4849C1.27314 17.9649 0.3815 17.7049 0.3815 17.2049C0.3815 16.7049 1.27314 16.4449 3.05643 15.9249L3.41126 15.8214L3.51473 15.4666C4.03472 13.6833 4.29472 12.7917 4.79475 12.7917C5.29479 12.7917 5.55479 13.6833 6.07478 15.4666L6.17825 15.8214L6.53308 15.9249C8.31637 16.4449 9.20801 16.7049 9.20801 17.2049C9.20801 17.7049 8.31637 17.9649 6.53308 18.4849L6.17825 18.5884L6.07478 18.9432Z"
            fill="url(#paint1_linear_198_13514)"
          />
          <path
            d="M15.161 12.9329C14.381 15.6078 13.991 16.9453 13.241 16.9453C12.4909 16.9453 12.1009 15.6079 11.3209 12.9329L10.812 11.1875L9.06657 10.6786C6.39165 9.89858 5.05418 9.50859 5.05418 8.75854C5.05418 8.00848 6.39165 7.61849 9.06657 6.8385L10.812 6.32955L11.3209 4.58415C12.1009 1.90922 12.4909 0.571762 13.241 0.571762C13.991 0.571762 14.381 1.90922 15.161 4.58415L15.6699 6.32955L17.4153 6.8385C20.0903 7.61849 21.4277 8.00848 21.4277 8.75854C21.4277 9.50859 20.0903 9.89858 17.4153 10.6786L15.6699 11.1875L15.161 12.9329Z"
            fill="url(#paint2_linear_198_13514)"
          />
          <path
            d="M15.161 12.9329C14.381 15.6078 13.991 16.9453 13.241 16.9453C12.4909 16.9453 12.1009 15.6079 11.3209 12.9329L10.812 11.1875L9.06657 10.6786C6.39165 9.89858 5.05418 9.50859 5.05418 8.75854C5.05418 8.00848 6.39165 7.61849 9.06657 6.8385L10.812 6.32955L11.3209 4.58415C12.1009 1.90922 12.4909 0.571762 13.241 0.571762C13.991 0.571762 14.381 1.90922 15.161 4.58415L15.6699 6.32955L17.4153 6.8385C20.0903 7.61849 21.4277 8.00848 21.4277 8.75854C21.4277 9.50859 20.0903 9.89858 17.4153 10.6786L15.6699 11.1875L15.161 12.9329Z"
            fill="url(#paint3_linear_198_13514)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_198_13514"
              x1="4.79475"
              y1="21.6182"
              x2="4.79475"
              y2="12.7917"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#415EED" />
              <stop offset="1" stopColor="#3651DA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_198_13514"
              x1="4.79475"
              y1="21.6182"
              x2="4.79475"
              y2="12.7917"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" stopOpacity="0.6" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_198_13514"
              x1="13.241"
              y1="16.9453"
              x2="13.241"
              y2="0.571762"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#415EED" />
              <stop offset="1" stopColor="#3651DA" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_198_13514"
              x1="13.241"
              y1="16.9453"
              x2="13.241"
              y2="0.571762"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" stopOpacity="0.3" />
            </linearGradient>
          </defs>
        </svg>
        <h3>Basic</h3>
        <p className="description">For Individuals and Business Owners</p>
      </div>
      <div className="line-h"></div>

      <div className="current-plan-bot">
        <p className="credits-mo">
          Credits / mo.{" "}
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                fill="url(#paint0_linear_190_24261)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                fill="url(#paint1_linear_190_24261)"
                fillOpacity="0.8"
                style={{ mixBlendMode: "soft-light" }}
              />
              <defs>
                <linearGradient
                  id="paint0_linear_190_24261"
                  x1="9"
                  y1="1.5"
                  x2="9"
                  y2="16.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#415EED" />
                  <stop offset="1" stopColor="#3651DA" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_190_24261"
                  x1="1.5"
                  y1="16.5"
                  x2="16.8965"
                  y2="16.0814"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F7F8FC" stopOpacity="0" />
                  <stop offset="0.494792" stopColor="#F7F8FC" />
                  <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            100
          </span>
        </p>
        <p className="credits-mo">
          Cost / mo.
          <span>
            <span>{currency}</span>{price[0]}{price[1]}
          </span>
        </p>
        <p className="credits-mo">
          Cost / credit
          <span>
            <span>{currency}</span>{price[2]}
          </span>
        </p>
        <p className="credits-mo billed">
          Billed<span>Monthly</span>
        </p>
      </div>
    </div>
  );
}

export default BasicPlanM;
