import React, { useState, useEffect } from "react";
import { endPointAdress } from "../../config";
function SetNewPass({ switchToLogin, onInputChange, inputValue }) {
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [isValidLogin, setIsValid] = useState(false);
  const [isPasswordRepeatFocused, setIsPasswordRepeatFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  useEffect(() => {
    document.querySelector("#mainRoot").className = "";
  }, []);
  useEffect(() => {
    const validate = () => {
      if (!passwordLogin || passwordLogin.length < 8) return false;
      if (!passwordRepeat || passwordRepeat.length < 8) return false;

      return true;
    };
    setIsValid(validate());
  }, [passwordRepeat, passwordLogin, inputValue]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
  };

  const handleSubmit = () => {
    const regex = /^[A-Za-z\d.,!@#$%^&*]+$/;

    function Abetka() {
      document.querySelector("#fail1").classList.add("true");
      let inputErrorT = document.querySelector("#passwordTrue");
      inputErrorT.classList.add("faili");
      inputErrorT.focus();
      setPasswordLogin("");
      setPasswordRepeat("");
      let inputError = document.querySelector("#passwordRepeat");
      document.querySelector("#fail2").classList.remove("true");
      inputError.classList.remove("faili");
    }
    function Rep() {
      let inputError = document.querySelector("#passwordRepeat");
      document.querySelector("#fail2").classList.add("true");
      inputError.classList.add("faili");
      setPasswordRepeat("");
      inputError.focus();

      document.querySelector("#fail1").classList.remove("true");
      let inputErrorT = document.querySelector("#passwordTrue");
      inputErrorT.classList.remove("faili");

      return;
    }
    if (regex.test(passwordLogin)) {
      if (passwordLogin !== passwordRepeat) {
        Rep();
      } else {
        const params = new URLSearchParams(window.location.search);
        const auth = params.get("auth");
        fetch(`${endPointAdress}reset-pass`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: auth,
            password: passwordRepeat,
          }),
        })
          .then((response) => {
            console.log("Status code:", response.status);
            if (response.status === 200) {
              switchToLogin();
              const newUrl = window.location.pathname;
              window.history.replaceState({}, "", newUrl);
            }
            return response.json();
          })
          .then((data) => {
            console.log("Response data:", data);
            console.log(auth);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } else {
      Abetka();
    }
  };

  return (
    <div className="auth__login">
      <h1 className="auth__login-h1">Set your new password</h1>

      <form onSubmit={handleSubmitLogin} className="auth__formLogin">
        <label className="auth__formLogin-label">
          <p style={{ color: isPasswordFocused ? "#3F5DE5" : "#6E738E" }}>
            Password:
          </p>
          <input
            id="passwordTrue"
            className="auth__formLogin-input"
            type="password"
            value={passwordLogin}
            onChange={(e) => setPasswordLogin(e.target.value)}
            placeholder="********"
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
          />
          <p id="fail1" className="fail">
            <i className="ri-information-line"></i>Only letters, numbers and
            common punctuation symbols are allowed
          </p>
        </label>
        <label className="auth__formLogin-label">
          <p style={{ color: isPasswordRepeatFocused ? "#3F5DE5" : "#6E738E" }}>
            Repeat Password:
          </p>
          <input
            id="passwordRepeat"
            className="auth__formLogin-input"
            type="password"
            value={passwordRepeat}
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
              onInputChange(e.target.value, "password");
            }}
            placeholder="********"
            onFocus={() => setIsPasswordRepeatFocused(true)}
            onBlur={() => setIsPasswordRepeatFocused(false)}
          />
          <p id="fail2" className="fail">
            <i className="ri-information-line"></i>Passwords don’t match
          </p>
        </label>

        <button
          className="auth__formLogin-button Reg"
          onClick={handleSubmit}
          type="submit"
          disabled={!isValidLogin}
        >
          <p>Set new Password</p>
        </button>
      </form>

      <button className="auth__forgotUser" onClick={switchToLogin}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );
}

export default SetNewPass;
