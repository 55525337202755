import { useEffect, useState } from "react";
import { endPointAdress } from './../../config';


function NotificationsTab() {
    const [switches, setSwitches] = useState(0);
    const [generationsNotifications, setGenerationsNotifications] = useState(0);
    const [systemLegalsUpdates, setSystemLegalsUpdates] = useState(0);
    const [newsletters, setNewsletters] = useState(0);
    const [isMounted, setIsMounted] = useState(true);
    const [isMountedTwo, setIsMountedTwo] = useState(false);
    useEffect(() => {
        if (switches) {
            document.querySelector('#switch').classList.add('active');
        } else {
            document.querySelector('#switch').classList.remove('active');
        } 
    }, [switches])
    useEffect(() => {
        if(generationsNotifications || systemLegalsUpdates || newsletters) {
            setSwitches(1);
        } else {
            setSwitches(0);
        }
        if (generationsNotifications) {
            document.querySelector('#generationsNotifications').classList.add('active');
        } else {
            document.querySelector('#generationsNotifications').classList.remove('active')
        }

        if (systemLegalsUpdates) {
            document.querySelector('#systemLegalsUpdates').classList.add('active');
        } else {
            document.querySelector('#systemLegalsUpdates').classList.remove('active');
        }

        if (newsletters) {
            document.querySelector('#newsletters').classList.add('active');
        } else {
            document.querySelector('#newsletters').classList.remove('active');
        }
    }, [switches, generationsNotifications, systemLegalsUpdates, newsletters ])

    useEffect(() => {
        
        fetch(`${endPointAdress}email-notifications` , {
            method: 'POST', 
        })
        .then(response => {
            if(response.ok) {
                return response.json()
            }
        })
        .then(data => {
            console.log(data);

            if(data.generation){
                setGenerationsNotifications(1);
            }
            if(data.system){
                setSystemLegalsUpdates(1);
            }
            if(data.newsletter){
                setNewsletters(1);
            }
            setIsMountedTwo(true);
            
        })
    }, [])

    const switchs = () => {
        if (switches) {
            setSwitches(0);
            setGenerationsNotifications(0);
            setSystemLegalsUpdates(0);
            setNewsletters(0);
            

        } else {
            setSwitches(1);
            setGenerationsNotifications(1);
            setSystemLegalsUpdates(1);
            setNewsletters(1);
            
        }
    }

    const sendGen = () => {
        if (!generationsNotifications) {
           
            setGenerationsNotifications(1);
            
        } else {
         
            setGenerationsNotifications(0);
            
        }

        
    };

    const sendSys = () => {
        
        if (!systemLegalsUpdates) {
    
            setSystemLegalsUpdates(1);
            
        } else {
          
            setSystemLegalsUpdates(0);
            
        }

        
    };

    const sendLet = () => {
        

        if (!newsletters) {
            
            setNewsletters(1);
            
        } else {
          
            setNewsletters(0);
            
        }
    };

    useEffect(() => {
        if (isMountedTwo) {
            if (isMounted) {
                setIsMounted(false);
            } else {
                setNoti();
            }
        }
       
    }, [generationsNotifications, systemLegalsUpdates, newsletters]);

    const setNoti = () => {
        fetch(`${endPointAdress}email-notifications` , {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                generation : generationsNotifications,
                system: systemLegalsUpdates,
                newsletter: newsletters,

            })
        })
        .catch(error => {
            console.error('Error occurred:', error);
          });
    };
    
    return (
        <div className="notification">
            <div className="notification__wrapper">
                <div className="notificatio__wrapper-item">
                    <p className="notificatio__wrapper-item-p">
                        Send me Email Notifications
                    </p>
                    <div id='switch' className="notificatio__wrapper-item-switch" onClick={() => switchs()}>
                        <div></div>
                    </div>
                </div>

                <div className="notification__line"></div>

                <div className="notificatio__wrapper-item">
                    <p className="notificatio__wrapper-item-p">
                        Logo Generations Notifications
                    </p>
                    <div id='generationsNotifications' className="notificatio__wrapper-item-checkbox" onClick={()=> sendGen()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M7.49955 11.3787L14.3938 4.48438L15.4545 5.54503L7.49955 13.5L2.72656 8.72704L3.78722 7.66639L7.49955 11.3787Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                
                <div className="notification__line"></div>

                <div className="notificatio__wrapper-item">
                    <p className="notificatio__wrapper-item-p">
                        System and Legals Documents Updates
                    </p>
                    <div id='systemLegalsUpdates' className="notificatio__wrapper-item-checkbox" onClick={()=> sendSys()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M7.49955 11.3787L14.3938 4.48438L15.4545 5.54503L7.49955 13.5L2.72656 8.72704L3.78722 7.66639L7.49955 11.3787Z" fill="white"/>
                        </svg>
                    </div>
                </div>

                <div className="notification__line"></div>

                <div className="notificatio__wrapper-item">
                    <p className="notificatio__wrapper-item-p">
                        Newsletter 
                    </p>
                    <div id='newsletters' className="notificatio__wrapper-item-checkbox" onClick={()=> sendLet()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M7.49955 11.3787L14.3938 4.48438L15.4545 5.54503L7.49955 13.5L2.72656 8.72704L3.78722 7.66639L7.49955 11.3787Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationsTab;