import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';

const SwitchVersions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef(null);
  const plan = useSelector((state) => state.plan);

  const getVersionFromPath = (path) => {
    return path.includes('/v1') ? 'V1.0' : 'Pro';
  };

  const [selectedVersion, setSelectedVersion] = useState(getVersionFromPath(location.pathname));

  useEffect(() => {
    setSelectedVersion(getVersionFromPath(location.pathname));
  }, [location]);

  useClickAway(ref, () => {
    if (isOpen) setIsOpen(false);
  });

  const handleSelect = (version, path) => {
    setSelectedVersion(version);
    navigate(path);
    setIsOpen(false);
  };

  return (
    <div className="switch-versions" ref={ref}>
      <div className={`switch-versions__selector ${isOpen}`} onClick={() => setIsOpen(!isOpen)}>
        {selectedVersion} <i className="ri-arrow-drop-down-line"></i>
      </div>
      {isOpen && (
        <div className="switch-versions__dropdown">
          <div className={`switch-versions__option ${selectedVersion === 'V1.0' && 'active'}`} onClick={() => handleSelect('V1.0', '/v1')}>
            V1.0 <i className="ri-check-fill"></i>
          </div>
          <div className={`switch-versions__option ${selectedVersion === 'Pro' && 'active'}`} onClick={() => handleSelect('Pro', '/')}>
            Pro <i className="ri-check-fill"></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default SwitchVersions;
