import React, { useState, useEffect } from "react";
import GraphicsBlockAuth from "./ui/graphicsBlockAuth";
import AuthMain from "./authPage/authMain";
import GraphicsBlockRegister from "./ui/graphicsBlockRegister";
import { useSelector } from "react-redux";
import { endPointAdress } from "./config";
import "./authPage/style/css/auth.css";

function MainAuthPage() {
  const stateUser = useSelector((state) => state);
  const [activeGraphicsBlock, setActiveGraphicsBlock] =
    useState("GraphicsBlockAuth");
  const [activeAuth, setActiveAuth] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    const attribute = params.get("page");
    if (attribute  === 'registration') {
      return "AuthReg"
    } else {
      return "AuthLogin"
    }
  });
  useEffect(() => {
    if (stateUser.applicationState === "payment") {
      setActiveAuth("RegStep4");
    } else if (stateUser.applicationState === "email-installed") {
      setActiveAuth("AuthEmail");
    } else if (stateUser.applicationState === "forgot") {
      setActiveAuth("SetNewPass");
    }

    if (stateUser.applicationState === "relogin") {
      fetch(`${endPointAdress}logout`, {
        method: "POST",
      });
      localStorage.clear();
    }
  }, [stateUser.applicationState]);

  useEffect(() => {
    if (
      activeAuth === "RegStep1" ||
      activeAuth === "RegStep2" ||
      activeAuth === "RegStep3" ||
      activeAuth === "RegStep4"
    ) {
      setActiveGraphicsBlock("GraphicsBlockRegister");
    } else {
      setActiveGraphicsBlock("GraphicsBlockAuth");
    }
  }, [activeAuth]);
  const renderGraphicsBlock = () => {
    switch (activeGraphicsBlock) {
      case "GraphicsBlockAuth":
        return <GraphicsBlockAuth />;
      case "GraphicsBlockRegister":
        return <GraphicsBlockRegister />;
      default:
        return <GraphicsBlockAuth />;
    }
  };
  return (
    <div className="auth">
      {renderGraphicsBlock()}
      <AuthMain activeAuth={activeAuth} setActiveAuth={setActiveAuth} />
    </div>
  );
}

export default MainAuthPage;
