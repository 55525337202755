import { endPointAdress } from "./../config";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "./../actions";
import trialImg from "./../img/trial_img.png";

function AppStyle({ pricing }) {
  const stateUser = useSelector((state) => state);
  const dispatch = useDispatch();
  const newPlanUpdate = () => dispatch(actions.newPlan());
  const [trialing, setTrialing] = useState(localStorage.getItem("status"));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    dispatch({ type: "CREDITS", payload: localStorage.getItem("credits") });
    newPlanUpdate();
  }, [localStorage.getItem("credits")]);

  const UpgradeTrieal = () => {
    fetch(`${endPointAdress}user/subscription`, {
      method: "PUT",
    })
      .then((response) => {
        if (response.status === 200) {
          toggleTriealPopup();

          localStorage.setItem("status", "active");
          setTrialing("active");
          return response.json();
        } else if (response.status === 404) {
        } else if (response.status === 409) {
        }
      })
      .then((data) => {
        dispatch({ type: "CREDITS", payload: data.credits });
        localStorage.setItem("credits", data.credits);
        dispatch({ type: "STATUS", newStatus: "active" });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const trialRef = useRef(null);
  const trialBgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const popup = document.querySelector(`.popup-trial`);
      if (
        trialBgRef.current.contains(event.target) &&
        !trialRef.current.contains(event.target)
      ) {
        popup.style.opacity = "0";
        setTimeout(() => {
          popup.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const toggleTriealPopup = () => {
    const popupEmail = document.querySelector(`.popup-trial`);
    if (popupEmail.style.display === "none") {
      popupEmail.style.display = "flex";
      setTimeout(() => {
        popupEmail.style.opacity = "1";
      }, 50);
    } else {
      popupEmail.style.opacity = "0";
      setTimeout(() => {
        popupEmail.style.display = "none";
      }, 300);
    }
  };

  return (
    <div
      className="user-credits"
      style={
        stateUser.plan === "Pro" &&
        stateUser.status !== "trialing" &&
        stateUser.credits > 15
          ? { display: "none" }
          : {}
      }
    >
      <div className="class-cr">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
            fill="url(#paint0_linear_2608_9672)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
            fill="url(#paint1_linear_2608_9672)"
            fillOpacity="0.8"
            style={{ mixBlendMode: "soft-light" }}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2608_9672"
              x1="9"
              y1="1.5"
              x2="9"
              y2="16.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#415EED" />
              <stop offset="1" stopColor="#3651DA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2608_9672"
              x1="1.5"
              y1="16.5"
              x2="16.8965"
              y2="16.0814"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7F8FC" stopOpacity="0" />
              <stop offset="0.494792" stopColor="#F7F8FC" />
              <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p>{stateUser.credits}</p>
      </div>
      <p
        onClick={toggleTriealPopup}
        className={`trial-mod ${
          trialing === "trialing" && windowWidth > 375 ? "active" : ""
        }`}
      >
        TRIAL
      </p>
      <i className="ri-add-line" onClick={pricing}></i>
      <div
        ref={trialBgRef}
        className="popup-trial"
        style={{ display: "none", opacity: "0" }}
      >
        <div ref={trialRef}>
          <img src={trialImg} alt="" />
          <h2>You are on a free trial</h2>
          <p>
            For free trial users we limit their access to{" "}
            <span>15 daily generations.</span> Upgrade to selected plan to break
            the limit.
          </p>
          <button onClick={UpgradeTrieal} className="upgrade-trial">
            <p>Upgrade</p>
          </button>
          <button onClick={toggleTriealPopup} className="cancel-trial">
            <p>Cancel</p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AppStyle;
