import CryptoJS from "crypto-js";
let encryptionSeed = "385h52m27s81ms";

var stateUser = {
  applicationState: "loading",
  loading: false,
  nickname: "user",
  email: "user.exemple.com",
  avatar: "basic",
  credits: 0,
  plan: "none",
  year: false,
  status: localStorage.getItem("status"),
  formattedDate: false,
  Auth: "auth",
  generation: true,
  currency: {
    symbol: "$",
    code: "USD",
    priceBasicM: [" 9", ".99", "0.1"],
    priceBasicA: [" 7", ".99", "0.08"],
    priceProM: [" 39", ".99"],
    priceProA: [" 31", ".99"],
  },
  
};
const reducer = (state = stateUser, action) => {
  switch (action.type) {
    case "AUTHTRUE":
      return {
        ...state,
        applicationState: "logins",
      };

    case "AUTHFALSE":
      return {
        ...state,
        applicationState: "relogin",
      };

    case "PAYMENTFALSE":
      return {
        ...state,
        applicationState: "payment",
      };

    case "EMAILFALSE":
      return {
        ...state,
        applicationState: "email-installed",
      };

    case "FORGOT":
      return {
        ...state,
        applicationState: "forgot",
      };

    case "USERDATA":
      let decryptedAlphaBytes = CryptoJS.AES.decrypt(
        localStorage.getItem("alphaID"),
        encryptionSeed
      );
      let decryptedBetaBytes = CryptoJS.AES.decrypt(
        localStorage.getItem("betaID"),
        encryptionSeed
      );
      let decryptedGammaRefBytes = CryptoJS.AES.decrypt(
        localStorage.getItem("gammaRef"),
        encryptionSeed
      );

      let decryptedNickname = decryptedAlphaBytes.toString(CryptoJS.enc.Utf8);
      let decryptedEmail = decryptedBetaBytes.toString(CryptoJS.enc.Utf8);
      let decryptedAvatar = decryptedGammaRefBytes.toString(CryptoJS.enc.Utf8);

      return {
        ...state,
        applicationState: state.applicationState,
        nickname: decryptedNickname,
        avatar: decryptedAvatar,
        email: decryptedEmail,
      };

    case "NEWPLAN":
      return {
        ...state,
        plan: localStorage.getItem("plan"),
      };

    case "YEARPLANTRUE":
      return {
        ...state,
        year: true,
      };

    case "YEARPLANFALSE":
      return {
        ...state,
        year: false,
      };

    case "CREDITS":
      return {
        ...state,
        credits: action.payload,
      };
    case "STATUS":
      return {
        ...state,
        status: action.newStatus,
      };
    case "DATE":
      return {
        ...state,
        formattedDate: action.newFormattedDate,
      };
    case "LOAD":
      return {
        ...state,
        loading: true,
      };
    case "CLOSED":
      return {
        ...state,
        loading: false,
      };
    case "AUTH":
      return {
        ...state,
        Auth: action.userType,
      };
    case "GENERATIONCHECK":
      return {
        ...state,
        generation: action.generationCheck,
      };
      case "CURRENCY":
        let setCurrency;
        let setPriceBasicAnnually;
        let setPriceProAnnually;
        let setPriceBasicMonthly;
        let setPriceProMonthly;

        if(action.payload === "EUR"){
          setCurrency = "€";
          setPriceBasicAnnually = [" 4", ".10", "0.04"];
          setPriceProAnnually = [" 16", ".60"];
          setPriceBasicMonthly = [" 4", ".99", "0.05"];
          setPriceProMonthly = [" 19", ".99"];
        }else if(action.payload === "GBP"){
          setCurrency = "£";
          setPriceBasicAnnually = [" 1", ".60", "0.016"];
          setPriceProAnnually = [" 8", ".30"];
          setPriceBasicMonthly = [" 1", ".99", "0.02"];
          setPriceProMonthly = [" 9", ".99"];
        } else if(action.payload === "USD"){
          setCurrency = "$";
          setPriceBasicAnnually = [" 7", ".99", "0.08"];
          setPriceProAnnually = [" 31", ".99"];
          setPriceBasicMonthly = [" 9", ".99", "0.1"];
          setPriceProMonthly = [" 39", ".99"];
        } else if(action.payload === "CNY"){
          setCurrency = "¥";
          setPriceBasicAnnually = [" 41", ".60", "0.42"];
          setPriceProAnnually = [" 166", ".60"];
          setPriceBasicMonthly = [" 49", ".99", "0.5"];
          setPriceProMonthly = [" 199", ".99"];
        }
      return {
        ...state,
        currency: {
          symbol: setCurrency,
          code: action.payload,
          priceBasicM: setPriceBasicMonthly,
          priceBasicA: setPriceBasicAnnually,
          priceProM: setPriceProMonthly,
          priceProA: setPriceProAnnually,
        },
      };

    default:
      return state;
  }
};

export default reducer;
