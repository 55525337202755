function CreatorPlanM({
  popup,
  currentPlan,
  toggleUpgradeSubscription,
  currency,
  price
}) {
  return (
    <div className="plan-billing__wrapper-manage">
      {currentPlan === "trialing" && (
        <p className="plan-billing__wrapper-manage-free">
          You are on 3-days free trial
        </p>
      )}
      <p className="plan-billing__wrapper-manage-your">Your plan:</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
      >
        <path
          d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
          fill="url(#paint0_linear_2761_15416)"
        />
        <path
          d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
          fill="url(#paint1_linear_2761_15416)"
        />
        <path
          d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
          fill="url(#paint2_linear_2761_15416)"
        />
        <path
          d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
          fill="url(#paint3_linear_2761_15416)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2761_15416"
            x1="6.72699"
            y1="28.1582"
            x2="6.72699"
            y2="16.3895"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#415EED" />
            <stop offset="1" stopColor="#3651DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2761_15416"
            x1="6.72699"
            y1="28.1582"
            x2="6.72699"
            y2="16.3895"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" stopOpacity="0.6" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2761_15416"
            x1="17.9886"
            y1="21.9277"
            x2="17.9886"
            y2="0.0963326"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#415EED" />
            <stop offset="1" stopColor="#3651DA" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2761_15416"
            x1="17.9886"
            y1="21.9277"
            x2="17.9886"
            y2="0.0963326"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" stopOpacity="0.3" />
          </linearGradient>
        </defs>
      </svg>
      <p className="plan-billing__wrapper-manage-plan">Basic</p>
      <p className="plan-billing__wrapper-manage-description">
        For Individuals and Business Owners
      </p>
      <p className="pay-price app">
        <span className="dolar">{currency}</span> {price[0]}
        <span className="ninety-nine">{price[1]}</span>
        <span className="savings">
          ≈ {currency}{price[2]} <span>/ credit</span>
        </span>
      </p>
      <p className="month app">
        <span>/ Month,</span> Billed Monthly
      </p>
      <button
        className="button-manage__edit"
        onClick={() => toggleUpgradeSubscription()}
      >
        Upgrade Plan
      </button>
      <button className="button-maname__cancel" onClick={() => popup()}>
        Cancel Subscription
      </button>
    </div>
  );
}

export default CreatorPlanM;
