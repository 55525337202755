import React, { useState, useEffect } from "react";
import { endPointAdress } from "../../config";
import { useDispatch } from "react-redux";

function RegStep3({ switchToLogin, switchToStep4, switchLogo, inputValue }) {
  const [username, setUsername] = useState("");
  const [isValidLogin, setIsValid] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    switchLogo();
    document.querySelectorAll(".process")[1].classList.add("blue");
    document.querySelectorAll(".process")[2].classList.add("blue");
    const validate = () => {
      if (!username || username.length < 2) return false;
      return true;
    };
    setIsValid(validate());
  }, [username, switchLogo, inputValue]);

  const handleSubmitLogin = (e) => {
    e.preventDefault();
  };

  const handleSubmit = () => {
    const regex = /^[A-Za-z0-9_-]+$/;
    function Abetka() {
      document.querySelector("#fail1").classList.add("true");
      let inputErrorT = document.querySelector("#nickTrue");
      inputErrorT.classList.add("faili");
      inputErrorT.focus();
      setUsername("");
    }
    if (!regex.test(username)) {
      Abetka();
    } else {
      fetch(`${endPointAdress}create-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic",
        },
        body: JSON.stringify({
          email: inputValue.email.toLowerCase(),
          password: inputValue.password,
          username: username,
        }),
      })
        .then((response) => {
          console.log("Status code:", response.status);
          console.log(username);
          console.log(inputValue.password);
          console.log(inputValue.email.toLowerCase());
          if (response.status === 200) {
            response.json();
          }
        })
        .then((data) => {
          switchToStep4();
          dispatch({ type: "CURRENCY", payload: data.currency });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  useEffect(() => {
    document.querySelector("#mainRoot").className = "";
  }, []);
  return (
    <div className="auth__login">
      <h1 className="auth__login-h1">What should we call you?</h1>
      <form onSubmit={handleSubmitLogin} className="auth__formLogin">
        <label className="auth__formLogin-label">
          <p style={{ color: isPasswordFocused ? "#3F5DE5" : "#6E738E" }}>
            Nickname:
          </p>
          <input
            id="nickTrue"
            className="auth__formLogin-input"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value.replace(/\s/g, ""))}
            placeholder="e.g. JohnDoe"
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
          />
          <p id="fail1" className="fail">
            <i className="ri-information-line"></i>Usernames must be written in
            Latin script. Please avoid using Cyrillic or other non-standard
            characters.
          </p>
        </label>
        <button
          className="auth__formLogin-button Reg"
          onClick={handleSubmit}
          type="submit"
          disabled={!isValidLogin}
        >
          <p>
            Next<i className="ri-arrow-right-fill"></i>
          </p>
        </button>
      </form>
      <button className="auth__forgotUser" onClick={switchToLogin}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );
}

export default RegStep3;
