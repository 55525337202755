import React, { useState, useEffect } from "react";
import AuthLogin from "./authComon/authLogin";
import AuthReg from "./authComon/authReg";
import ForgotPass from "./authComon/forgotPass";
import Logo from "./../ui/svgLogolivery";
import RegStep1 from "./authStepReg/stepRegOne";
import RegStep2 from "./authStepReg/stepRegTwo";
import RegStep3 from "./authStepReg/stepRegTree";
import RegStep4 from "./authStepReg/stepRegFour";
import ProcessStep from "./../ui/processStep";
import ForgotSend from "./authComon/forgotSend";
import SetNewPassword from "./authComon/setwNewPass.js";
import AuthEmail from "./authComon/authEmail";
import CodeEmail from "./authComon/codeEmail";
import * as actions from "../actions";
import { useSelector, useDispatch } from "react-redux";

function AuthMain({ activeAuth, setActiveAuth }) {
  const stateUser = useSelector((state) => state);
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const checkAuthTrue = () => dispatch(actions.authTrue());
  const checkAuthFalse = () => dispatch(actions.authFalse());
  const checkPaymentFalse = () => dispatch(actions.paymentFalse());
  const checkEmailFalse = () => dispatch(actions.emailFalse());
  const checkForgot = () => dispatch(actions.forgot());

  const [activeNumberStep, setNumberStep] = useState(0);
  const [activeStep, setActiveStep] = useState("Process1");
  const [authRegInput, setAuthRegInput] = useState({
    email: "",
    password: "",
  });

  const handleAuthRegInputChange = (newValue, inputName) => {
    setAuthRegInput((prevState) => ({
      ...prevState,
      [inputName]: newValue,
    }));
  };
  useEffect(() => {
    if (
      activeAuth === "RegStep1" ||
      activeAuth === "RegStep2" ||
      activeAuth === "RegStep3" ||
      activeAuth === "RegStep4"
    ) {
      if (activeAuth === "RegStep1") {
        setNumberStep(0);
      } else if (activeAuth === "RegStep2") {
        setNumberStep(1);
      } else if (activeAuth === "RegStep3") {
        setNumberStep(2);
      } else if (activeAuth === "RegStep4") {
        setNumberStep(3);
      }
      setActiveStep("Process1");
    } else if (activeAuth === "AuthReg") {
      setActiveStep("Logo");
    }
  }, [activeAuth]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderAuth = () => {
    switch (activeAuth) {
      case "AuthLogin":
        return (
          <AuthLogin
            switchToReg={() => setActiveAuth("AuthReg")}
            switchToForgot={() => setActiveAuth("ForgotPass")}
            switchLogo={() => setActiveStep("Logo")}
            setNewPass={() => setActiveAuth("SetNewPass")}
            switchToStep4={() => setActiveAuth("RegStep4")}
          />
        );
      case "AuthReg":
        return (
          <AuthReg
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchLogo={() => setActiveStep("Process1")}
            switchToStep1={() => setActiveAuth("RegStep1")}
            switchToStep2={() => setActiveAuth("RegStep2")}
            onInputChange={handleAuthRegInputChange}
          />
        );
      case "AuthEmail":
        return (
          <AuthEmail
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchLogo={() => setActiveStep("Process1")}
            switchToCodeEmail={() => setActiveAuth("CodeEmail")}
            onInputChange={handleAuthRegInputChange}
          />
        );

      case "CodeEmail":
        return (
          <CodeEmail
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchToReg={() => setActiveAuth("AuthEmail")}
            inputValue={authRegInput}
            onInputChange={handleAuthRegInputChange}
          />
        );
      case "ForgotPass":
        return (
          <ForgotPass
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchToSend={() => setActiveAuth("ForgotSend")}
            onInputChange={handleAuthRegInputChange}
          />
        );
      case "RegStep1":
        return (
          <RegStep1
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchLogo={() => setActiveStep("Process1")}
            switchToStep2={() => setActiveAuth("RegStep2")}
            switchToReg={() => setActiveAuth("AuthReg")}
            inputValue={authRegInput}
            onInputChange={handleAuthRegInputChange}
          />
        );
      case "RegStep2":
        return (
          <RegStep2
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchLogo={() => setActiveStep("Process1")}
            switchToStep3={() => setActiveAuth("RegStep3")}
            inputValue={authRegInput}
            onInputChange={handleAuthRegInputChange}
          />
        );
      case "RegStep3":
        return (
          <RegStep3
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchLogo={() => setActiveStep("Process1")}
            switchToStep4={() => setActiveAuth("RegStep4")}
            inputValue={authRegInput}
            onInputChange={handleAuthRegInputChange}
          />
        );
      case "RegStep4":
        return (
          <RegStep4
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchLogo={() => setActiveStep("Process1")}
            inputValue={authRegInput}
          />
        );
      case "ForgotSend":
        return (
          <ForgotSend
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
            }}
            switchToForgot={() => setActiveAuth("ForgotPass")}
            inputValue={authRegInput}
          />
        );
      case "SetNewPass":
        return (
          <SetNewPassword
            switchToLogin={() => {
              setActiveAuth("AuthLogin");
              checkAuthFalse();
              const newUrl = window.location.pathname;
              window.history.replaceState({}, "", newUrl);
            }}
          />
        );
      default:
        return (
          <AuthLogin
            switchToReg={() => setActiveAuth("AuthReg")}
            switchToForgot={() => setActiveAuth("ForgotPass")}
          />
        );
    }
  };
  const renderStep = () => {
    switch (activeStep) {
      case "Logo":
        return <Logo />;
      case "Process1":
        return <ProcessStep howStep={activeNumberStep} />;
      default:
        return <Logo />;
    }
  };
  return (
    <div className="auth__main">
      {windowWidth < 768 && activeStep === "Process1" && <Logo />}
      {renderStep()}
      {renderAuth()}
    </div>
  );
}

export default AuthMain;
