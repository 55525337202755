import { endPointAdress } from "../../config";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useEffect, useState, useRef } from "react";
import AppGen from "./../appGen";
import AppStyle from "./../appStyle";
import Credits from "./../appCredit";
import limit50 from "./../../img/limit50.png";
import SwitchVersions from "./switchVersions";

function DefaultVersions({ pricing }) {
  const listContainerRef = useRef(null);
  const lastElementRef = useRef(null);
  const newElementRef = useRef(null);
  const [isMounted, setIsMounted] = useState(true);
  const [isMountedTwo, setIsMountedTwo] = useState(false);
  const [list, setList] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [visibleList, setVisibleList] = useState([]);
  const [visibleListGen, setVisibleListGen] = useState([]);
  const [mobileAdaptiv, setMobileAdaptiv] = useState(() => {
    if (window.innerWidth < 768) {
      return true;
    } else {
      return false;
    }
  });

  const [selectedOptions, setSelectedOptions] = useState([
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ]);
  const stateUser = useSelector((state) => state.generation, shallowEqual);
  const dispatch = useDispatch();

  const togglelimitPopup50 = () => {
    const popup = document.querySelector(`#popup-limit`);
    if (popup.style.display === "none") {
      popup.style.display = "flex";
      setTimeout(() => {
        popup.style.opacity = "1";
      }, 50);
    } else {
      popup.style.opacity = "0";
      setTimeout(() => {
        popup.style.display = "none";
      }, 300);
    }
  };

  const checkGeneration = () => {
    fetch(`${endPointAdress}generation`, {
      method: "POST",
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (document.querySelector("#error400")) {
            document.querySelector("#error400").style.display = "flex";
          }
          throw new Error("Network response was not ok");
        }
      })
      .then((data) => {
        if (data !== undefined) {
          setIsMountedTwo(true);

          const newListPromises = Array.from(
            { length: data.count },
            async (_, i) => {
              try {
                let response = await fetch(
                  `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                    data.user_id
                  }/${data.random_key}/${i + 1}/${
                    data.user_id + "_" + (i + 1) + ".json"
                  }`,
                  {
                    headers: {
                      "Access-Control-Allow-Origin":
                        "https://app.logolivery.ai",
                    },
                  }
                );

                if (!response.ok && data.count === i + 1) {
                  const svgPromises = Array.from({ length: 5 }, (_, q) => {
                    if (q === 4) {
                      return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                        data.user_id
                      }/${data.random_key}/${i + 1}/${
                        data.user_id + "_" + (i + 1) + ".json"
                      }`;
                    } else {
                      return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                        data.user_id
                      }/${data.random_key}/${i + 1}/${
                        i + 1 + "_" + data.user_id + "_" + q + ".svg"
                      }`;
                    }
                  });
      
                  return Promise.all(svgPromises).then((arraySvg) => ({
                    index: i + 1,
                    svgs: arraySvg,
                  }));
                } else if (!response.ok) {
                  return undefined;
                }

                const svgPromises = Array.from({ length: 5 }, (_, q) => {
                  if (q === 4) {
                    return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                      data.user_id
                    }/${data.random_key}/${i + 1}/${
                      data.user_id + "_" + (i + 1) + ".json"
                    }`;
                  } else {
                    return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                      data.user_id
                    }/${data.random_key}/${i + 1}/${
                      i + 1 + "_" + data.user_id + "_" + q + ".svg"
                    }`;
                  }
                });

                return Promise.all(svgPromises).then((arraySvg) => ({
                  index: i + 1,
                  svgs: arraySvg,
                }));
              } catch (error) {
                console.error("Fetching data failed:", error);
                return undefined;
              }
            }
          );

          Promise.all(newListPromises)
            .then((newList) => {
              // Фільтруємо undefined значення і обертаємо список
              const filteredList = newList.filter((item) => item !== undefined);
              setList([...filteredList.reverse()]);
            })
            .catch((error) => {
              console.error("Error in newListPromises:", error);
            });
        }
      });
  };

  // const checkGeneration = () => {
  //   fetch(`${endPointAdress}generation`, {
  //     method: "POST",
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         return response.json();
  //       } else {
  //         if (document.querySelector("#error400")) {
  //           document.querySelector("#error400").style.display = "flex";
  //         }
  //       }
  //       throw new Error("Network response was not ok");
  //     })
  //     .then((data) => {
  //       if (data !== undefined) {
  //         setIsMountedTwo(true);

  //         const newListPromises = Array.from({ length: data.count }, (_, i) => {
  //           const svgPromises = Array.from({ length: 5 }, (_, q) => {
  //             if (q === 4) {

  //               return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
  //                 data.user_id
  //               }/${data.random_key}/${i + 1}/${
  //                 data.user_id + "_" + (i + 1) + ".json"
  //               }`;
  //             } else {
  //               return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
  //                 data.user_id
  //               }/${data.random_key}/${i + 1}/${
  //                 i + 1 + "_" + data.user_id + "_" + q + ".svg"
  //               }`;
  //             }
  //           });
  //           return Promise.all(svgPromises).then((arraySvg) => ({
  //             index: i + 1,
  //             svgs: arraySvg,
  //           }));
  //         });
  //         Promise.all(newListPromises)
  //           // Коли отримуєте новий список:
  //           .then((newList) => setList([...newList.reverse()]))

  //           .catch((error) =>
  //             console.error("Error in newListPromises:", error)
  //           );
  //       }
  //     });
  // };

  useEffect(() => {
    checkGeneration();
  }, []);

  function submitOnEnter(event) {
    if (event.which === 13) {
      if (!event.repeat) {
        const newEvent = new Event("submit", { cancelable: true });
        event.target.form.dispatchEvent(newEvent);
      }
      event.preventDefault(); // Prevents the addition of a new line in the text field
    }
  }

  const toggleTriealPopup = () => {
    const popup = document.querySelector(`.popup-trial`);
    if (popup.style.display === "none") {
      popup.style.display = "flex";
      setTimeout(() => {
        popup.style.opacity = "1";
      }, 50);
    } else {
      popup.style.opacity = "0";
      setTimeout(() => {
        popup.style.display = "none";
      }, 300);
    }
  };

  useEffect(() => {
    function generationStart(event) {
      event.preventDefault();
      fetch(`${endPointAdress}generation`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: document.getElementById("usermsg").value,
          parameters: selectedOptions,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 466) {
            toggleTriealPopup();
          } else if (response.status === 467) {
            togglelimitPopup50();
          }
        })
        .then((data) => {
          if (data !== undefined) {
            localStorage.setItem("credits", data.credits);
            const newListPromises = Array.from({ length: 1 }, (_, i) => {
              const svgPromises = Array.from({ length: 5 }, (_, q) => {
                if (q === 4) {
                  return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                    data.user_id
                  }/${data.random_key}/${data.count}/${
                    data.user_id + "_" + data.count + ".json"
                  }`;
                } else {
                  return `https://logoliveryai.sfo3.cdn.digitaloceanspaces.com/logoliveryai/${
                    data.user_id
                  }/${data.random_key}/${data.count}/${
                    data.count + "_" + data.user_id + "_" + q + ".svg"
                  }`;
                }
              });
              return Promise.all(svgPromises).then((arraySvg) => ({
                index: data.count,
                svgs: arraySvg,
              }));
            });
            Promise.all(newListPromises)
              .then((newList) =>
                setVisibleList((prev) => {
                  return [...newList, ...prev];
                })
              )
              .catch((error) =>
                console.error("Error in newListPromises:", error)
              );
          }

          dispatch({ type: "CREDITS", payload: data.credits });
          document.getElementById("usermsg").value = "";
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    }
    const formElement = document.getElementById("form");
    // && stateUser
    if (formElement && stateUser) {
      formElement.addEventListener("submit", generationStart);
      return () => {
        formElement.removeEventListener("submit", generationStart);
      };
    }
  }, [selectedOptions, stateUser]);

  useEffect(() => {
    document
      .getElementById("usermsg")
      .addEventListener("keydown", submitOnEnter);
    document
      .getElementById("pasteButton")
      .addEventListener("click", async function () {
        try {
          const text = await navigator.clipboard.readText();
          document.getElementById("usermsg").value = text;
        } catch (err) {
          console.error("Failed to read clipboard contents: ", err);
        }
      });
    document.querySelector("#mainRoot").classList.remove("auto-height");
  }, []);

  const handleSelect = (value, index) => {
    const newSelectedOptions = [...selectedOptions];
    if (index !== 5) {
      newSelectedOptions[index] = value;
      setSelectedOptions(newSelectedOptions);
    }

    if (index === 5 && selectedOptions[5] === "none") {
      newSelectedOptions[index] = "Negative Space";
      setSelectedOptions(newSelectedOptions);
      document.querySelector(".negative-space").classList.add("active");
    } else if (index === 5 && selectedOptions[5] !== "none") {
      newSelectedOptions[index] = "none";
      setSelectedOptions(newSelectedOptions);
      document.querySelector(".negative-space").classList.remove("active");
    }
  };

  const autoScroll = () => {
    if (newElementRef.current) {
      const container = newElementRef.current;
      container.scrollTop = container.scrollHeight - container.clientHeight;
    }
  };

  useEffect(() => {
    if (list.length === 0) {
      return;
    } else if (visibleList.length < 2) {
      setVisibleList([list[0]]);
      setVisibleListGen([list[0]]);
    }
    const currentRef = lastElementRef.current;
    if (isMountedTwo) {
      if (isMounted) {
        setIsMounted(false);
      } else {
        if (visibleListGen.length !== visibleList.length) {
          autoScroll();
        }

        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (visibleListGen.length === visibleList.length) {
                setVisibleList((prev) => {
                  const nextItems = list.slice(prev.length, prev.length + 4);
                  setVisibleListGen([...prev, ...nextItems]);
                  return [...prev, ...nextItems];
                });
              } else {
                setVisibleList((prev) => {
                  setVisibleListGen([...prev]);
                  return [...prev];
                });
              }
            }
          });
        });
        if (currentRef) {
          observer.observe(currentRef);
        }
        return () => {
          if (currentRef) {
            observer.unobserve(currentRef);
          }
        };
      }
    }
  }, [list, visibleList.length, visibleListGen.length]);

  const handleButtonClick = () => {
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
    }, 1000);
  };

  useEffect(() => {
    if (mobileAdaptiv) {
      document.querySelector("#mainRoot").className = "";
    } else {
      document.querySelector("#mainRoot").className = "";
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setMobileAdaptiv(() => {
        if (window.innerWidth < 768) {
          return true;
        } else {
          return false;
        }
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrolledFromTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.scrollHeight;
      const isAtBottom = scrolledFromTop + windowHeight + 250 >= docHeight;
      setIsVisible(isAtBottom);
    }; // мобільна анімація для інпута вводу промпта
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let startY;
    const handleTouchStart = (event) => {
      startY = event.touches[0].clientY;
    };

    const handleTouchMove = (event) => {
      const currentY = event.touches[0].clientY;
      // Якщо користувач проводить вгору (тобто стартова позиція більше поточної)
      if (startY < currentY) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    const handleScroll = (event) => {
      if (event.deltaY < 0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    };
    const scrollableElement = newElementRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("touchstart", handleTouchStart);
      scrollableElement.addEventListener("touchmove", handleTouchMove);
      scrollableElement.addEventListener("wheel", handleScroll);
    }

    // Прибирання обробників
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("touchstart", handleTouchStart);
        scrollableElement.removeEventListener("touchmove", handleTouchMove);
        scrollableElement.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  const limitRef = useRef(null);
  const limitBgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const popup = document.querySelector(`#popup-limit`);
      if (
        limitBgRef.current.contains(event.target) &&
        !limitRef.current.contains(event.target)
      ) {
        popup.style.opacity = "0";
        setTimeout(() => {
          popup.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const closePopup = () => {
    document.querySelector("#error400").style.display = "none";
  };
  // disabled={!stateUser}
  return (
    <div className="chat" ref={listContainerRef}>
      <div
        className="chat__wrapper"
        style={mobileAdaptiv ? { overflow: "hidden" } : {}}
      >
        {!mobileAdaptiv ? <Credits pricing={pricing} /> : null}
        <SwitchVersions />
        <h3 className="chat__wrapper-h">Generation Queue</h3>
        <div
          ref={newElementRef}
          className="chat__wrapper-content"
          id="scrollElement"
        >
          {visibleList.map((item) => (
            <AppGen
              key={item.index}
              text={item}
              ref={
                item.index === visibleList[visibleList.length - 1].index
                  ? lastElementRef
                  : null
              }
            />
          ))}
          <div className="void"></div>
        </div>

        <div
          style={!stateUser && mobileAdaptiv ? { display: "none" } : {}}
          className={`chat__wrapper-input ${
            isVisible && stateUser ? "visible" : ""
          }`}
        >
          <form id="form" className="form-input">
            <textarea
              id="usermsg"
              placeholder="Enter your prompt..."
            ></textarea>
            <p
              id="pasteButton"
              style={buttonClicked ? { color: "#3F5DE5" } : {}}
              onClick={handleButtonClick}
            >
              Paste{" "}
              {buttonClicked ? (
                <i
                  style={buttonClicked ? { color: "#3F5DE5" } : {}}
                  className="ri-check-line"
                ></i>
              ) : (
                <i className="ri-file-copy-line"></i>
              )}
            </p>
            <button disabled={!stateUser} type="Submit">
              <span>Generate</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                  fill="currentColor"
                />
              </svg>
              <span>-1</span>
            </button>
          </form>
          <div className="parameters">
            <p className="parameters-p">
              Parameters:
              <i
                onClick={() =>
                  window.open(
                    "https://docs.logolivery.ai/getting-started/parameters-v2"
                  )
                }
                className="ri-information-line"
              ></i>
            </p>

            <AppStyle
              title={"Style"}
              options={[
                "Minimalistic",
                "Techy",
                "Outlined",
                "Hand-Drawn",
                "Luxury",
                "Medieval",
                "Antique",
                "Stroke",
                "Processual",
                "Pixel",
              ]}
              onSelect={(value) => handleSelect(value, 0)}
            />
            <AppStyle
              title={"Direction"}
              options={["Vertical", "Horizontal", "Diagonal"]}
              onSelect={(value) => handleSelect(value, 1)}
            />
            <AppStyle
              title={"Symmetry"}
              options={["Symmetry", "Asymmetry", "Inverted-Symmetry"]}
              onSelect={(value) => handleSelect(value, 2)}
            />
            <AppStyle
              title={"Corners"}
              options={["Neutral", "Rounded", "Strict"]}
              onSelect={(value) => handleSelect(value, 3)}
            />
            <AppStyle
              title={"Shape"}
              options={[
                "Rectangle",
                "Square",
                "Triangle",
                "Circle",
                "Diamond",
                "Oval",
                "Free-Form",
              ]}
              onSelect={(value) => handleSelect(value, 4)}
            />

            <div onClick={() => handleSelect("", 5)} className="negative-space">
              Negative Space
            </div>
          </div>
        </div>
      </div>

      <div
        ref={limitBgRef}
        className="popup-limit"
        id="popup-limit"
        style={{ display: "none", opacity: "0" }}
      >
        <div ref={limitRef}>
          <img src={limit50} alt="" />
          <h2>You’ve reached your generation limit</h2>
          <p>
            In safety terms we limit our users to 50 generations per{" "}
            <span>3 hours</span>. Wait a bit to comtinue your experience.
          </p>

          <button onClick={togglelimitPopup50} className="cancel-trial">
            <p>Cancel</p>
          </button>
        </div>
      </div>
      <div id="error400" className="popupError settings">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="#FF5D52"
          />
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="url(#paint0_linear_2202_9790)"
            fillOpacity="0.7"
            style={{ mixBlendMode: "soft-light" }}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2202_9790"
              x1="2"
              y1="22"
              x2="22"
              y2="2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7F8FC" stopOpacity="0" />
              <stop offset="0.494206" stopColor="#F7F8FC" />
              <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p>
          Currently experiencing technical difficulties. Thanks for your
          patience!
        </p>
        <i onClick={closePopup} className="ri-close-line"></i>
      </div>
    </div>
  );
}

export default DefaultVersions;
