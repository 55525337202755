import React, { useEffect } from "react";
function ProgressStep(props) {
  console.log(props.howStep);
  useEffect(() => {
    for (let i = 0; props.howStep > i; i++) {
      document.querySelectorAll(".process")[i + 1].classList.add("blue");
    }
  }, [props.howStep]);
  return (
    <div className="auth__step1">
      <div className="process blue"></div>
      <div className="process"></div>
      <div className="process"></div>
      <div className="process"></div>
    </div>
  );
}

export default ProgressStep;
