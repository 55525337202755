function GraphicsBlockAuth() {
  return (
    <div className="auth__img">
      <div className="auth__img-img">
        <img src="./img/png/authImg/graphics-block.svg" alt="graphics-block" />
      </div>
    </div>
  );
}
export default GraphicsBlockAuth;
