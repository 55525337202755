import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { endPointAdress } from "../../config";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import * as actions from "./../../actions";
let encryptionSeed = "385h52m27s81ms";

function AuthLogin({
  switchToReg,
  switchToForgot,
  switchLogo,
  authTrue,
  counter,
  paymentFalse,
}) {
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [isValidLogin, setIsValid] = useState(false);
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const userDataUpdate = () => dispatch(actions.userData());
  const event = new Event("dataUpdated");
  const newPlanUpdate = () => dispatch(actions.newPlan());
  window.dispatchEvent(event);
  useEffect(() => {
    switchLogo();
    const validate = () => {
      if (!emailLogin || !emailLogin.includes("@")) return false;
      if (!passwordLogin || passwordLogin.length < 8) return false;
      return true;
    };
    setIsValid(validate());
  }, [emailLogin, passwordLogin, switchLogo]);
  const handleSubmitLogin = (e) => {
    e.preventDefault();
    if (isValidLogin) {
      console.log("Submitting login form with email and password");
    } else {
      console.log("Form is not valid");
    }
  };
  const handleSubmit = () => {
    function ErrorUser() {
      document.querySelector("#fail1").classList.add("true");
      let inputErrorT = document.querySelector("#emailTrue");
      let inputPErrorT = document.querySelector("#passwordTrue");
      inputErrorT.classList.add("faili");
      inputPErrorT.classList.remove("faili");
      inputErrorT.focus();
      setEmailLogin("");
      setPasswordLogin("");
      document.querySelector(".popupError").style.display = "none";
    }
    function Error() {
      document.querySelector("#fail1").classList.remove("true");
      let inputErrorT = document.querySelector("#emailTrue");
      let inputPErrorT = document.querySelector("#passwordTrue");
      inputErrorT.classList.add("faili");
      inputPErrorT.classList.add("faili");
      inputErrorT.focus();
      document.querySelector(".popupError").style.display = "flex";
      document.querySelector(".popupError p").textContent =
        "Email and Password do not match";
      setEmailLogin("");
      setPasswordLogin("");
    }
    fetch(`${endPointAdress}sign-in`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic",
      },
      body: JSON.stringify({
        email: emailLogin.toLowerCase(),
        password: passwordLogin,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          authTrue();
          return response.json();
        } else if (response.status === 404) {
          ErrorUser();
        } else if (response.status === 403) {
          Error();
        } else if (response.status === 402) {
          paymentFalse();
        }
      })
      .then((data) => {
        localStorage.setItem(
          "gammaRef",
          CryptoJS.AES.encrypt(data.ava, encryptionSeed).toString()
        );
        localStorage.setItem(
          "betaID",
          CryptoJS.AES.encrypt(data.email, encryptionSeed).toString()
        );
        localStorage.setItem("plan", data.plan);
        localStorage.setItem(
          "alphaID",
          CryptoJS.AES.encrypt(data.nickname, encryptionSeed).toString()
        );
        localStorage.setItem("credits", data.credits);
        localStorage.setItem("status", data.status);
        userDataUpdate();
        newPlanUpdate();
        dispatch({ type: "STATUS", newStatus: data.status });
        dispatch({ type: "CREDITS", payload: data.credits });
        dispatch({ type: "AUTH", userType: data.user_type });
      })

      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const OAuthDiscord = () => {
    window.location.href =
      "https://discord.com/api/oauth2/authorize?client_id=1088438869730545776&redirect_uri=https%3A%2F%2Fapp.logolivery.ai%2F&response_type=code&scope=identify%20email&state=discord";
  };
  const OAuthGit = () => {
    window.location.href =
      "https://github.com/login/oauth/authorize?client_id=c65080b62e1dab02a073&scope=read%3Auser+user%3Aemail&redirect_uri=https%3A%2F%2Fapp.logolivery.ai%2F&state=github";
  };
  const OAuthGoogle = () => {
    window.location.href =
      "https://accounts.google.com/o/oauth2/v2/auth?client_id=494438075956-2v96t773b2foi7ooqgg81g5kgje4a81v.apps.googleusercontent.com&response_type=code&scope=openid+email&redirect_uri=https%3A%2F%2Fapp.logolivery.ai%2F&state=google";
  };
  function closePopup() {
    document.querySelector(".popupError").style.display = "none";
  }
  useEffect(() => {
    if (windowWidth < 768) {
      document.querySelector("#mainRoot").className = "auto-height-login";
    } else {
      document.querySelector("#mainRoot").className = "";
    }
  }, [windowWidth]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="auth__login pop">
      <div className="popupError">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="#FF5D52"
          />
          <path
            d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z"
            fill="url(#paint0_linear_2202_9790)"
            fillOpacity="0.7"
            style={{ mixBlendMode: "soft-light" }}
          />
          <defs>
            <linearGradient
              id="paint0_linear_2202_9790"
              x1="2"
              y1="22"
              x2="22"
              y2="2"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F7F8FC" stopOpacity="0" />
              <stop offset="0.494206" stopColor="#F7F8FC" />
              <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p>Email and Password do not match</p>
        <i onClick={closePopup} className="ri-close-line"></i>
      </div>
      <h1 className="auth__login-h1">Log in to your account</h1>
      <div className="auth__button">
        <button onClick={OAuthGoogle} className="auth__button-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M2.80801 6.88305C4.31634 3.88718 7.41628 1.83301 10.9997 1.83301C13.4705 1.83301 15.5454 2.74138 17.133 4.22051L14.5038 6.84969C13.5539 5.94137 12.3454 5.47884 10.9997 5.47884C8.61214 5.47884 6.59128 7.09135 5.87051 9.25801C5.68713 9.80801 5.58296 10.3955 5.58296 10.9997C5.58296 11.6038 5.68713 12.1913 5.87051 12.7413C6.59128 14.908 8.61214 16.5205 10.9997 16.5205C12.233 16.5205 13.283 16.1955 14.1039 15.6455C15.0746 14.9955 15.7205 14.0247 15.933 12.8788H10.9997V9.33299H19.6329C19.7414 9.93295 19.7997 10.558 19.7997 11.208C19.7997 13.9996 18.7997 16.3496 17.0663 17.9455C15.5496 19.3456 13.4747 20.1663 10.9997 20.1663C7.41628 20.1663 4.31634 18.1122 2.80801 15.1163C2.18719 13.8788 1.83301 12.4788 1.83301 10.9997C1.83301 9.52054 2.18719 8.12052 2.80801 6.88305Z"
              fill="#333853"
            />
          </svg>
        </button>
        <button onClick={OAuthGit} className="auth__button-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M11.0007 1.83301C5.93607 1.83301 1.83398 5.93509 1.83398 10.9997C1.83398 15.0559 4.45794 18.482 8.10169 19.6965C8.56003 19.7768 8.7319 19.5018 8.7319 19.2611C8.7319 19.0434 8.72044 18.3215 8.72044 17.5538C6.41732 17.9778 5.82148 16.9924 5.63815 16.4768C5.53503 16.2132 5.08815 15.3997 4.69857 15.182C4.37773 15.0101 3.9194 14.5861 4.68711 14.5747C5.40898 14.5632 5.92461 15.2393 6.09648 15.5143C6.92148 16.9007 8.23919 16.5111 8.76628 16.2705C8.84648 15.6747 9.08711 15.2736 9.35067 15.0445C7.31107 14.8153 5.17982 14.0247 5.17982 10.5184C5.17982 9.52155 5.53503 8.69655 6.1194 8.05488C6.02773 7.82572 5.7069 6.88613 6.21107 5.62572C6.21107 5.62572 6.97878 5.38509 8.7319 6.5653C9.46525 6.35905 10.2444 6.25592 11.0236 6.25592C11.8028 6.25592 12.5819 6.35905 13.3153 6.5653C15.0684 5.37363 15.8361 5.62572 15.8361 5.62572C16.3403 6.88613 16.0194 7.82572 15.9278 8.05488C16.5121 8.69655 16.8673 9.51009 16.8673 10.5184C16.8673 14.0361 14.7246 14.8153 12.685 15.0445C13.0173 15.3309 13.3038 15.8809 13.3038 16.7403C13.3038 17.9663 13.2923 18.9518 13.2923 19.2611C13.2923 19.5018 13.4642 19.7882 13.9225 19.6965C17.6538 18.4369 20.1663 14.9379 20.1673 10.9997C20.1673 5.93509 16.0653 1.83301 11.0007 1.83301Z"
              fill="#333853"
            />
          </svg>
        </button>
        <button onClick={OAuthDiscord} className="auth__button-item">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M17.6948 4.89272C16.4399 4.31877 15.1071 3.90047 13.716 3.66699C13.5409 3.96857 13.3463 4.37714 13.2101 4.69817C11.7305 4.48415 10.2615 4.48415 8.80234 4.69817C8.66614 4.37714 8.46184 3.96857 8.29646 3.66699C6.89563 3.90047 5.56289 4.31877 4.31672 4.89272C1.79715 8.60884 1.11619 12.2374 1.45667 15.8173C3.12989 17.0334 4.74478 17.7727 6.33142 18.2591C6.72055 17.7338 7.07076 17.1695 7.37234 16.5761C6.79837 16.3621 6.2536 16.0995 5.72829 15.7882C5.86448 15.6909 6.00068 15.5839 6.12715 15.4769C9.29848 16.9264 12.7335 16.9264 15.8658 15.4769C16.0021 15.5839 16.1286 15.6909 16.2648 15.7882C15.7394 16.0995 15.1947 16.3621 14.6207 16.5761C14.9223 17.1695 15.2725 17.7338 15.6616 18.2591C17.2473 17.7727 18.8719 17.0334 20.5364 15.8173C20.9546 11.6732 19.8739 8.07384 17.6948 4.89272ZM7.81009 13.6091C6.85673 13.6091 6.07848 12.7433 6.07848 11.683C6.07848 10.6226 6.83727 9.75677 7.81009 9.75677C8.77315 9.75677 9.56111 10.6226 9.54167 11.683C9.54167 12.7433 8.77315 13.6091 7.81009 13.6091ZM14.2025 13.6091C13.249 13.6091 12.4698 12.7433 12.4698 11.683C12.4698 10.6226 13.2296 9.75677 14.2025 9.75677C15.1655 9.75677 15.9535 10.6226 15.934 11.683C15.934 12.7433 15.1752 13.6091 14.2025 13.6091Z"
              fill="#333853"
            />
          </svg>
        </button>
      </div>
      <div className="line__login">
        <div className="line__login-h"></div>
        <p className="line__login-text">or</p>
        <div className="line__login-h"></div>
      </div>
      <form onSubmit={handleSubmitLogin} className="auth__formLogin">
        <label className="auth__formLogin-label">
          <p style={{ color: isEmailFocused ? "#3F5DE5" : "#6E738E" }}>
            Email:<span> *</span>
          </p>
          <input
            id="emailTrue"
            className="auth__formLogin-input"
            type="email"
            value={emailLogin}
            onChange={(e) => setEmailLogin(e.target.value)}
            placeholder="johndoe@mail.com"
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
            autoComplete="email"
          />
          <p id="fail1" className="fail">
            <i className="ri-information-line"></i>Account with this email
            doesn’t exist
          </p>
        </label>
        <label className="auth__formLogin-label">
          <p style={{ color: isPasswordFocused ? "#3F5DE5" : "#6E738E" }}>
            Password:<span> *</span>{" "}
            <span className="switchToForgot" onClick={switchToForgot}>
              Forgot password?
            </span>
          </p>
          <input
            id="passwordTrue"
            className="auth__formLogin-input"
            type="password"
            value={passwordLogin}
            onChange={(e) => setPasswordLogin(e.target.value)}
            placeholder="********"
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
            autoComplete="current-password"
          />
        </label>
        <button
          className="auth__formLogin-button"
          onClick={handleSubmit}
          type="submit"
          disabled={!isValidLogin}
        >
          <p>Log In with email</p>
        </button>
      </form>

      <p className="auth__registerUser">
        Don’t have an account?{" "}
        <span onClick={switchToReg}>Sign up for free</span>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    counter: state.applicationState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authTrue: () => dispatch({ type: "AUTHTRUE" }),
    authFalse: () => dispatch({ type: "AUTHFALSE" }),
    paymentFalse: () => dispatch({ type: "PAYMENTFALSE" }),
    emailFalse: () => dispatch({ type: "EMAILFALSE" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLogin);
