import React, { useState, useEffect } from "react";
import { endPointAdress } from "../../config";
import { useSelector } from "react-redux";



function RegStepFour({ switchToLogin }) {
  const stateUser = useSelector(state => state.currency);

  const [activeTab, setActiveTab] = useState(getRandomInt(0, 1));
  const [activePlan, setActivePlan] = useState(activeTab ? 3 : 1);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const currency = stateUser.symbol;
  const priceBasicAnnually = stateUser.priceBasicA;
  const priceProAnnually = stateUser.priceProA;
  const priceBasicMonthly = stateUser.priceBasicM;
  const priceProMonthly = stateUser.priceProM;

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

 

  useEffect(() => {
    let infIcon = document.querySelectorAll(".ri-information-line");
    if (infIcon.length > 0) {
      let infPopupElements = document.querySelectorAll(".inf-popup");
      infIcon[0].addEventListener("mouseenter", () => {
        infPopupElements[0].style.opacity = 1;
      });
      infIcon[1].addEventListener("mouseenter", () => {
        infPopupElements[1].style.opacity = 1;
      });
      infIcon[2].addEventListener("mouseenter", () => {
        infPopupElements[2].style.opacity = 1;
      });
      infIcon[3].addEventListener("mouseenter", () => {
        infPopupElements[3].style.opacity = 1;
      });

      infIcon[0].addEventListener("mouseleave", () => {
        infPopupElements[0].style.opacity = 0;
      });
      infIcon[1].addEventListener("mouseleave", () => {
        infPopupElements[1].style.opacity = 0;
      });
      infIcon[2].addEventListener("mouseleave", () => {
        infPopupElements[2].style.opacity = 0;
      });
      infIcon[3].addEventListener("mouseleave", () => {
        infPopupElements[3].style.opacity = 0;
      });
    }
  }, []);

  useEffect(() => {
    if (
      document
        .querySelector("#mainRoot")
        .classList.contains("auto-height-login")
    ) {
      document.querySelector("#mainRoot").classList.remove("auto-height-login");
    } 
    if (windowWidth < 768) {
      document.querySelector("#mainRoot").classList.add("auto-height");
    } else {
      document.querySelector("#mainRoot").classList.remove("auto-height");
    }
    if (windowWidth > 768) {
      document.querySelector(".auth__main").classList.add("step4");
    } else {
      document.querySelector(".auth__main").classList.remove("step4");
    }
  }, [windowWidth]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Paddle = window.Paddle;

  const openCheckout = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.body.style.overflow = "hidden";
    //console.log(emailUser);

    

    Paddle.Checkout.open({
      settings: {
        theme: "light",
      },

      customer: {
        email: emailUser,
      },

      customData: {
        sid: idUser,
      },
      items: [
        {
          priceId: activePlan === 0 ? product.Basic[1] : activePlan === 1 ? product.Pro[1] : activePlan === 2 ? product.Basic[0] : product.Pro[0],
          
          quantity: 1,
        },
      ],
    });
  };
  var emailUser = "";
  var idUser = "";
  var product = {};

  const validOpen = () => {
    fetch(`${endPointAdress}subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.log("Status code:", response.status);
        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
        if (data.email && data.id) {
          emailUser = data.email;
          idUser = data.id;
          product = data.prices
          openCheckout();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  window.onerror = function (msg, source, lineno, colno, error) {
    console.log("Message: " + msg);
    console.log("Source: " + source);
    console.log("Line: " + lineno);
    console.log("Column: " + colno);
    console.log("Error object: ", error);
    return true;
  };
  return (
    <div className="auth__login">
      <h1 className="auth__login-h1">Find the plan for you</h1>
      <p className="auth__login-p">
        Choose a plan to start your <span>3-days free trial</span>.<br />
        You can change your plan or cancel at any time.
        <br />{" "}
      </p>
      <div className="tabs-container">
        <div className="tabs-header">
          <button
            className={activeTab === 0 ? "active" : ""}
            onClick={() => setActiveTab(0)}
          >
            Annually<span>-20% OFF</span>
          </button>
          <button
            className={activeTab === 1 ? "active" : ""}
            onClick={() => setActiveTab(1)}
          >
            Monthly
          </button>
        </div>
        <div className="tabs-content">
          <div className={`tab-panel ${activeTab === 0 ? "active" : ""}`}>
            <div
              onClick={() => setActivePlan(0)}
              className={`pay-annuallyC ${activePlan === 0 ? "active" : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="35"
                viewBox="0 0 29 29"
                fill="none"
              >
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint0_linear_2191_5765)"
                />
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint1_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint2_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint3_linear_2191_5765)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                className="svg-active"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint0_linear_2191_5771)"
                />
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint1_linear_2191_5771)"
                  fillOpacity="0.8"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5771"
                    x1="12"
                    y1="2"
                    x2="12"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5771"
                    x1="2"
                    y1="22"
                    x2="22.5286"
                    y2="21.4418"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F7F8FC" stopOpacity="0" />
                    <stop offset="0.494792" stopColor="#F7F8FC" />
                    <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name">Basic</h3>
              <p className="pay-describution">
                For Individuals and <br />
                Business Owners
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceBasicAnnually[0]}
                <span className="ninety-nine">{priceBasicAnnually[1]}</span>
                <span className="savings">
                  ≈ {currency}{priceBasicAnnually[2]} <span>/ credit</span>
                </span>
              </p>
              <p className="month">
                / Month<span>, Billed Annually</span>
              </p>
              <div className="grid-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">100 Credits per Month</p>
                <p className="Logotypes">400 Logotypes</p>
                <i className="ri-information-line"></i>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
            <div
              onClick={() => setActivePlan(1)}
              className={`pay-annuallyB ${activePlan === 1 ? "active" : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="27"
                viewBox="0 0 28 27"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M26.9422 6.05654C26.6921 5.80649 26.353 5.66602 25.9993 5.66602H1.99935C1.64573 5.66602 1.30659 5.80649 1.05654 6.05654C0.806491 6.30659 0.666016 6.64573 0.666016 6.99935V25.666C0.666016 26.0196 0.806491 26.3588 1.05654 26.6088C1.30659 26.8589 1.64573 26.9993 1.99935 26.9993H25.9993C26.353 26.9993 26.6921 26.8589 26.9422 26.6088C27.1922 26.3588 27.3327 26.0196 27.3327 25.666V6.99935C27.3327 6.64573 27.1922 6.30659 26.9422 6.05654Z"
                  fill="url(#paint0_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint1_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint2_linear_2202_10001)"
                />
                <path
                  d="M7.33203 1.66732V5.66732H9.9987V3.00065H17.9987V5.66732H20.6654V1.66732C20.6654 1.3137 20.5249 0.974557 20.2748 0.724509C20.0248 0.47446 19.6857 0.333984 19.332 0.333984H8.66536C8.31174 0.333984 7.9726 0.47446 7.72256 0.724509C7.47251 0.974557 7.33203 1.3137 7.33203 1.66732Z"
                  fill="url(#paint3_linear_2202_10001)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="26.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="18.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2202_10001"
                    x1="27.2659"
                    y1="12.9041"
                    x2="0.315884"
                    y2="12.9041"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2202_10001"
                    x1="13.9987"
                    y1="0.333984"
                    x2="13.9987"
                    y2="5.66732"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                className="svg-active"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint0_linear_2191_5771)"
                />
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint1_linear_2191_5771)"
                  fillOpacity="0.8"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5771"
                    x1="12"
                    y1="2"
                    x2="12"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5771"
                    x1="2"
                    y1="22"
                    x2="22.5286"
                    y2="21.4418"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F7F8FC" stopOpacity="0" />
                    <stop offset="0.494792" stopColor="#F7F8FC" />
                    <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name top">Pro</h3>

              <p className="pay-describution">
                Best for Freelances,
                <br />
                Studios and Agencies
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceProAnnually[0]}
                <span className="ninety-nine">{priceProAnnually[1]}</span>
              </p>
              <p className="month">
                / Month<span>, Billed Annually</span>
              </p>
              <div className="grid-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">Unlimited Credits per Month</p>
                <p className="Logotypes">Unlimited Logotypes</p>
                <i className="ri-information-line"></i>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
          </div>
          <div className={`tab-panel ${activeTab === 1 ? "active" : ""}`}>
            <div
              onClick={() => setActivePlan(2)}
              className={`pay-annuallyC ${activePlan === 2 ? "active" : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="35"
                viewBox="0 0 29 29"
                fill="none"
              >
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint0_linear_2191_5765)"
                />
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint1_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint2_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint3_linear_2191_5765)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                className="svg-active"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint0_linear_2191_5771)"
                />
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint1_linear_2191_5771)"
                  fillOpacity="0.8"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5771"
                    x1="12"
                    y1="2"
                    x2="12"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5771"
                    x1="2"
                    y1="22"
                    x2="22.5286"
                    y2="21.4418"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F7F8FC" stopOpacity="0" />
                    <stop offset="0.494792" stopColor="#F7F8FC" />
                    <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name">Basic</h3>
              <p className="pay-describution">
                For Individuals and <br />
                Business Owners
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceBasicMonthly[0]}
                <span className="ninety-nine">{priceBasicMonthly[1]}</span>
                <span className="savings">
                  ≈ {currency}{priceBasicMonthly[2]} <span>/ credit</span>
                </span>
              </p>
              <p className="month">
                / Month<span>, Billed Monthly</span>
              </p>
              <div className="grid-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">100 Credits per Month</p>
                <p className="Logotypes">400 Logotypes</p>
                <i className="ri-information-line"></i>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
            <div
              onClick={() => setActivePlan(3)}
              className={`pay-annuallyB ${activePlan === 3 ? "active" : ""}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="27"
                viewBox="0 0 28 27"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M26.9422 6.05654C26.6921 5.80649 26.353 5.66602 25.9993 5.66602H1.99935C1.64573 5.66602 1.30659 5.80649 1.05654 6.05654C0.806491 6.30659 0.666016 6.64573 0.666016 6.99935V25.666C0.666016 26.0196 0.806491 26.3588 1.05654 26.6088C1.30659 26.8589 1.64573 26.9993 1.99935 26.9993H25.9993C26.353 26.9993 26.6921 26.8589 26.9422 26.6088C27.1922 26.3588 27.3327 26.0196 27.3327 25.666V6.99935C27.3327 6.64573 27.1922 6.30659 26.9422 6.05654Z"
                  fill="url(#paint0_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint1_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint2_linear_2202_10001)"
                />
                <path
                  d="M7.33203 1.66732V5.66732H9.9987V3.00065H17.9987V5.66732H20.6654V1.66732C20.6654 1.3137 20.5249 0.974557 20.2748 0.724509C20.0248 0.47446 19.6857 0.333984 19.332 0.333984H8.66536C8.31174 0.333984 7.9726 0.47446 7.72256 0.724509C7.47251 0.974557 7.33203 1.3137 7.33203 1.66732Z"
                  fill="url(#paint3_linear_2202_10001)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="26.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="18.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2202_10001"
                    x1="27.2659"
                    y1="12.9041"
                    x2="0.315884"
                    y2="12.9041"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2202_10001"
                    x1="13.9987"
                    y1="0.333984"
                    x2="13.9987"
                    y2="5.66732"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                className="svg-active"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint0_linear_2191_5771)"
                />
                <path
                  d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
                  fill="url(#paint1_linear_2191_5771)"
                  fillOpacity="0.8"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5771"
                    x1="12"
                    y1="2"
                    x2="12"
                    y2="22"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5771"
                    x1="2"
                    y1="22"
                    x2="22.5286"
                    y2="21.4418"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F7F8FC" stopOpacity="0" />
                    <stop offset="0.494792" stopColor="#F7F8FC" />
                    <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name top">Pro</h3>
              <p className="pay-describution">
                Best for Freelances,
                <br />
                Studios and Agencies
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceProMonthly[0]}
                <span className="ninety-nine">{priceProMonthly[1]}</span>
              </p>
              <p className="month">
                / Month<span>, Billed Monthly</span>
              </p>
              <div className="grid-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">Unlimited Credits per Month</p>
                <p className="Logotypes">Unlimited Logotypes</p>
                <i className="ri-information-line"></i>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className={`auth__formLogin-button Reg ${
          activePlan === undefined ? "disable" : ""
        }`}
        variant="primary"
        onClick={validOpen}
      >
        <p>
          {activePlan === 2 || activePlan === 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                opacity="0.7"
                d="M5.4853 17.2814C5.00864 18.9161 4.7703 19.7334 4.31194 19.7334C3.85357 19.7334 3.61524 18.9161 3.13858 17.2814L3.04374 16.9561L2.71847 16.8613C1.0838 16.3846 0.266457 16.1463 0.266457 15.6879C0.266457 15.2296 1.0838 14.9912 2.71847 14.5146L3.04374 14.4197L3.13858 14.0944C3.61524 12.4598 3.85357 11.6424 4.31194 11.6424C4.7703 11.6424 5.00864 12.4598 5.4853 14.0944L5.58014 14.4197L5.90541 14.5146C7.54008 14.9912 8.35742 15.2296 8.35742 15.6879C8.35742 16.1463 7.54008 16.3846 5.90541 16.8613L5.58014 16.9561L5.4853 17.2814Z"
                fill="white"
                fillOpacity="0.8"
              />
              <path
                d="M13.8141 11.7722C13.0991 14.2242 12.7416 15.4502 12.054 15.4502C11.3665 15.4502 11.009 14.2242 10.294 11.7722L9.82748 10.1722L8.22753 9.70569C5.77551 8.99069 4.5495 8.6332 4.5495 7.94565C4.5495 7.2581 5.77551 6.90061 8.22753 6.18562L9.82748 5.71908L10.294 4.11913C11.009 1.66711 11.3665 0.441106 12.054 0.441106C12.7416 0.441106 13.0991 1.66711 13.8141 4.11913L14.2806 5.71908L15.8806 6.18562C18.3326 6.90061 19.5586 7.2581 19.5586 7.94565C19.5586 8.6332 18.3326 8.99069 15.8806 9.70569L14.2806 10.1722L13.8141 11.7722Z"
                fill="url(#paint0_linear_2249_10072)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2249_10072"
                  x1="12.054"
                  y1="15.4502"
                  x2="12.054"
                  y2="0.441107"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" stopOpacity="0.6" />
                  <stop offset="0.525122" stopColor="white" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="27"
              viewBox="0 0 28 27"
              fill="none"
            >
              <path
                opacity="0.3"
                d="M26.9422 6.05654C26.6921 5.80649 26.353 5.66602 25.9993 5.66602H1.99935C1.64573 5.66602 1.30659 5.80649 1.05654 6.05654C0.806491 6.30659 0.666016 6.64573 0.666016 6.99935V25.666C0.666016 26.0196 0.806491 26.3588 1.05654 26.6088C1.30659 26.8589 1.64573 26.9993 1.99935 26.9993H25.9993C26.353 26.9993 26.6921 26.8589 26.9422 26.6088C27.1922 26.3588 27.3327 26.0196 27.3327 25.666V6.99935C27.3327 6.64573 27.1922 6.30659 26.9422 6.05654Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                fill="url(#paint0_linear_1898_19457)"
              />
              <path
                d="M7.33301 1.66732V5.66732H9.99967V3.00065H17.9997V5.66732H20.6663V1.66732C20.6663 1.3137 20.5259 0.974557 20.2758 0.724509C20.0258 0.47446 19.6866 0.333984 19.333 0.333984H8.66634C8.31272 0.333984 7.97358 0.47446 7.72353 0.724509C7.47348 0.974557 7.33301 1.3137 7.33301 1.66732Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1898_19457"
                  x1="27.2659"
                  y1="12.9041"
                  x2="0.315884"
                  y2="12.9041"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="white" stopOpacity="0.2" />
                  <stop offset="1" stopColor="white" />
                </linearGradient>
              </defs>
            </svg>
          )}
          {`Start with the ${
            activePlan === 2 || activePlan === 0 ? "Basic " : ""
          }${activePlan === 3 || activePlan === 1 ? "Pro " : ""}`}
          3-days trial
        </p>
      </button>{" "}
      <button className="auth__forgotUser" onClick={switchToLogin}>
        <i className="ri-close-line"></i>
      </button>
    </div>
  );
}

export default RegStepFour;
