import * as actions from "./../actions";
import { endPointAdress } from "./../config";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import BasicPlanA from "./popupPricing/BasicPlanA";
import BasicPlanM from "./popupPricing/BasicPlanM";
import ProPlanA from "./popupPricing/ProPlanA";
import ProPlanM from "./popupPricing/ProPlanM";

function AppPricing() {
  const stateUser = useSelector((state) => state);
  const [activeTab, setActiveTab] = useState(0);
  const [clickPlan, setClickPlan] = useState([0, false]);
  const [method, setMethod] = useState("switch");

  const currency = stateUser.currency.symbol;
  const priceBasicAnnually = stateUser.currency.priceBasicA;
  const priceProAnnually = stateUser.currency.priceProA;
  const priceBasicMonthly = stateUser.currency.priceBasicM;
  const priceProMonthly = stateUser.currency.priceProM;

  const currentPlanStatus = useSelector((state) => state.status);
  const dispatch = useDispatch();

  const yearPlanUpdate = () => dispatch(actions.yearPlan());
  const yearPlanF = () => dispatch(actions.yearPlanF());
  const userDataUpdate = () => dispatch(actions.userData());
  const newPlanUpdate = () => dispatch(actions.newPlan());
  //console.log('Email: ', inputValue);
  useEffect(() => {
    let infIcon = document.querySelectorAll(".ri-information-line");
    if (infIcon.length > 0) {
      console.log(infIcon.length, infIcon);
      let infPopupElements = document.querySelectorAll(".inf-popup");
      infIcon[1].addEventListener("mouseenter", () => {
        infPopupElements[0].style.opacity = 1;
      });
      infIcon[2].addEventListener("mouseenter", () => {
        infPopupElements[1].style.opacity = 1;
      });
      infIcon[3].addEventListener("mouseenter", () => {
        infPopupElements[2].style.opacity = 1;
      });
      infIcon[4].addEventListener("mouseenter", () => {
        infPopupElements[3].style.opacity = 1;
      });

      infIcon[1].addEventListener("mouseleave", () => {
        infPopupElements[0].style.opacity = 0;
      });
      infIcon[2].addEventListener("mouseleave", () => {
        infPopupElements[1].style.opacity = 0;
      });
      infIcon[3].addEventListener("mouseleave", () => {
        infPopupElements[2].style.opacity = 0;
      });
      infIcon[4].addEventListener("mouseleave", () => {
        infPopupElements[3].style.opacity = 0;
      });
    }
  }, []);
  const callNewSubscription = () => {
    if (stateUser.plan === "Basic") {
      console.log("pro");
      localStorage.setItem("plan", "Pro");
      userDataUpdate();
    } else if (stateUser.plan === "Pro") {
      console.log("pro");
      localStorage.setItem("plan", "Basic");
      userDataUpdate();
    }
    callPlanDetails();
  };

  //paddle
  function checkoutClosed(data) {
    userDataUpdate();
    toggleUpgradeSubscription();
    setTimeout(() => {
      callPlanDetails();
      Paddle.Checkout.close();
    }, 3000);
  }

  const Paddle = window.Paddle;

  const openCheckout = () => {
    document.body.style.overflow = "hidden";

    //console.log(emailUser);
    Paddle.Checkout.open({
      settings: {
        theme: "light",
      },
      customer: {
        email: emailUser,
      },
      customData: {
        sid: idUser,
      },
      items: [
        {
          priceId: productId,
          quantity: 1,
        },
      ],
    });
    //product: 'pri_01h8xneet2j88bfrtdv71ejjr5',
  };
  var emailUser = "";
  var idUser = "";
  var productId = "";

  const validOpen = () => {
    fetch(`${endPointAdress}user/subscription`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        method: method,
      }),
    })
      .then((response) => {
        console.log(response.status);
        if (response.status === 402) {
          console.log(method);
          return response.json();
        } else if (response.status === 200) {
          if (method === "switch") {
            callPlanDetails();
          } else {
            callNewSubscription();
          }

          toggleUpgradeSubscription(0, false);
        } else {
          console.log(method);
        }
      })
      .then((data) => {
        if (data.email && data.sid) {
          emailUser = data.email;
          idUser = data.sid;
          productId = data.price_id;
          openCheckout();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    callPlanDetails(true);
  }, []);

  const callPlanDetails = (a) => {
    fetch(`${endPointAdress}user/subscription`, {
      method: "POST",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
          console.log(response.text());
        }
      })
      .then((data) => {
        if (data.status === "cancel") {
          const nextBilledAt = new Date(data.scheduled_cancel);
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          dispatch({
            type: "DATE",
            newFormattedDate: `${
              months[nextBilledAt.getMonth()]
            } ${nextBilledAt.getDate()}, ${nextBilledAt.getFullYear()}`,
          });
        } else if (data.status !== "cancel" && data.status !== "canceled") {
          const nextBilledAt = new Date(data.next_billed_at);
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          dispatch({
            type: "DATE",
            newFormattedDate: `${
              months[nextBilledAt.getMonth()]
            } ${nextBilledAt.getDate()}, ${nextBilledAt.getFullYear()}`,
          });
        }
        if (data.billing_cycle === "year") {
          yearPlanUpdate();
        } else {
          yearPlanF();
        }
        localStorage.setItem("plan", data.type);
        if (data.type === "Basic" && data.status !== "trialing" && !a) {
          localStorage.setItem("credits", 100);
          dispatch({ type: "CREDITS", payload: 100 });
        } else if (data.type === "Pro" && data.status !== "trialing" && !a) {
          localStorage.setItem("credits", 500);
          dispatch({ type: "CREDITS", payload: 500 });
        }
        if (data.status !== localStorage.getItem("status")) {
          // setCurrentPlanStatus(data.status);
          dispatch({ type: "STATUS", newStatus: data.status });
          localStorage.setItem("status", data.status);
        }
        newPlanUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [newFlow, setNewFlow] = useState(0);
  const toggleUpgradeSubscription = (a, b) => {
    setClickPlan([a, b]);
    if (`${stateUser.plan}` !== a || stateUser.year !== b) {
      if (`${stateUser.plan}` === a && stateUser.year !== b) {
        setMethod("switch");
        console.log(method);
        console.log(`${stateUser.plan}`, a, stateUser.year, b);
      } else if (stateUser.plan !== a && a !== 0 && stateUser.year === b) {
        console.log(stateUser.plan, a, stateUser.year, b);
        setMethod("grade");
        console.log(method);
      } else if (stateUser.plan !== a && a !== 0 && stateUser.year !== b) {
        console.log(stateUser.plan, a, stateUser.year, b);
        setMethod("grade-switch");
        console.log(method);
      }
      const popup = document.querySelector(".popup__upgrade-subscription");

      if (popup.style.display === "none") {
        popup.style.display = "flex";

        document.body.style.overflow = "hidden";
        setTimeout(() => {
          popup.style.opacity = "1";
        }, 50);
      } else {
        popup.style.opacity = "0";
        document.body.style.overflow = "auto";
        setTimeout(() => {
          popup.style.display = "none";
        }, 300);
      }
    } else {
      console.log("renew");
      setMethod("renew");
      setNewFlow(1);
    }
  };

  useEffect(() => {
    if (newFlow) {
      validOpen();
      setNewFlow(0);
    }
  }, [newFlow]);
  function CardPlan() {
    if (clickPlan[0] === "Basic" && clickPlan[1]) {
      return <BasicPlanA currency={currency} price={priceBasicAnnually} />;
    } else if (clickPlan[0] === "Basic" && !clickPlan[1]) {
      return <BasicPlanM currency={currency} price={priceBasicMonthly} />;
    } else if (clickPlan[0] === "Pro" && clickPlan[1]) {
      return <ProPlanA currency={currency} price={priceProAnnually} />;
    } else if (clickPlan[0] === "Pro" && !clickPlan[1]) {
      return <ProPlanM currency={currency} price={priceProMonthly} />;
    }
  }

  function SvgPlan() {
    if (clickPlan[0] === "Basic" && clickPlan[1]) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.48481 17.2814C5.00815 18.9161 4.76982 19.7334 4.31145 19.7334C3.85309 19.7334 3.61476 18.9161 3.13809 17.2814L3.04325 16.9561L2.71798 16.8613C1.08331 16.3846 0.265968 16.1463 0.265968 15.6879C0.265968 15.2296 1.08331 14.9912 2.71798 14.5146L3.04325 14.4197L3.13809 14.0944C3.61476 12.4598 3.85309 11.6424 4.31145 11.6424C4.76982 11.6424 5.00815 12.4598 5.48481 14.0944L5.57965 14.4197L5.90492 14.5146C7.53959 14.9912 8.35693 15.2296 8.35693 15.6879C8.35693 16.1463 7.5396 16.3846 5.90492 16.8613L5.57965 16.9561L5.48481 17.2814Z"
            fill="CurrentColor"
          />
          <path
            d="M13.8141 11.7722C13.0991 14.2242 12.7416 15.4502 12.054 15.4502C11.3665 15.4502 11.009 14.2242 10.294 11.7722L9.82748 10.1722L8.22753 9.70569C5.77551 8.99069 4.5495 8.6332 4.5495 7.94565C4.5495 7.2581 5.77551 6.90061 8.22753 6.18562L9.82748 5.71908L10.294 4.11913C11.009 1.66711 11.3665 0.441106 12.054 0.441106C12.7416 0.441106 13.0991 1.66711 13.8141 4.11913L14.2806 5.71908L15.8806 6.18562C18.3326 6.90061 19.5586 7.2581 19.5586 7.94565C19.5586 8.6332 18.3326 8.99069 15.8806 9.70569L14.2806 10.1722L13.8141 11.7722Z"
            fill="CurrentColor"
          />
        </svg>
      );
    } else if (clickPlan[0] === "Basic" && !clickPlan[1]) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M5.48481 17.2814C5.00815 18.9161 4.76982 19.7334 4.31145 19.7334C3.85309 19.7334 3.61476 18.9161 3.13809 17.2814L3.04325 16.9561L2.71798 16.8613C1.08331 16.3846 0.265968 16.1463 0.265968 15.6879C0.265968 15.2296 1.08331 14.9912 2.71798 14.5146L3.04325 14.4197L3.13809 14.0944C3.61476 12.4598 3.85309 11.6424 4.31145 11.6424C4.76982 11.6424 5.00815 12.4598 5.48481 14.0944L5.57965 14.4197L5.90492 14.5146C7.53959 14.9912 8.35693 15.2296 8.35693 15.6879C8.35693 16.1463 7.5396 16.3846 5.90492 16.8613L5.57965 16.9561L5.48481 17.2814Z"
            fill="CurrentColor"
          />
          <path
            d="M13.8141 11.7722C13.0991 14.2242 12.7416 15.4502 12.054 15.4502C11.3665 15.4502 11.009 14.2242 10.294 11.7722L9.82748 10.1722L8.22753 9.70569C5.77551 8.99069 4.5495 8.6332 4.5495 7.94565C4.5495 7.2581 5.77551 6.90061 8.22753 6.18562L9.82748 5.71908L10.294 4.11913C11.009 1.66711 11.3665 0.441106 12.054 0.441106C12.7416 0.441106 13.0991 1.66711 13.8141 4.11913L14.2806 5.71908L15.8806 6.18562C18.3326 6.90061 19.5586 7.2581 19.5586 7.94565C19.5586 8.6332 18.3326 8.99069 15.8806 9.70569L14.2806 10.1722L13.8141 11.7722Z"
            fill="CurrentColor"
          />
        </svg>
      );
    } else if (clickPlan[0] === "Pro" && clickPlan[1]) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
        >
          <path
            opacity="0.3"
            d="M18.3979 4.85149C18.2259 4.67959 17.9928 4.58301 17.7497 4.58301H1.24967C1.00656 4.58301 0.773402 4.67959 0.601493 4.85149C0.429585 5.0234 0.333008 5.25656 0.333008 5.49967V18.333C0.333008 18.5761 0.429585 18.8093 0.601493 18.9812C0.773402 19.1531 1.00656 19.2497 1.24967 19.2497H17.7497C17.9928 19.2497 18.2259 19.1531 18.3979 18.9812C18.5698 18.8093 18.6663 18.5761 18.6663 18.333V5.49967C18.6663 5.25656 18.5698 5.0234 18.3979 4.85149Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7497 4.58301C17.9928 4.58301 18.2259 4.67959 18.3979 4.85149C18.5698 5.0234 18.6663 5.25656 18.6663 5.49967V13.7497H0.333008V5.49967C0.333008 5.25656 0.429585 5.0234 0.601493 4.85149C0.773402 4.67959 1.00656 4.58301 1.24967 4.58301H17.7497ZM8.58301 11.9163V10.083H10.4163V11.9163H8.58301Z"
            fill="url(#paint0_linear_198_2787)"
          />
          <path
            d="M4.9165 1.83366V4.58366H6.74984V2.75033H12.2498V4.58366H14.0832V1.83366C14.0832 1.59054 13.9866 1.35739 13.8147 1.18548C13.6428 1.01357 13.4096 0.916992 13.1665 0.916992H5.83317C5.59006 0.916992 5.3569 1.01357 5.18499 1.18548C5.01308 1.35739 4.9165 1.59054 4.9165 1.83366Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_198_2787"
              x1="18.6204"
              y1="9.55918"
              x2="0.0922922"
              y2="9.55918"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.3" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else if (clickPlan[0] === "Pro" && !clickPlan[1]) {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
        >
          <path
            opacity="0.3"
            d="M18.3979 4.85149C18.2259 4.67959 17.9928 4.58301 17.7497 4.58301H1.24967C1.00656 4.58301 0.773402 4.67959 0.601493 4.85149C0.429585 5.0234 0.333008 5.25656 0.333008 5.49967V18.333C0.333008 18.5761 0.429585 18.8093 0.601493 18.9812C0.773402 19.1531 1.00656 19.2497 1.24967 19.2497H17.7497C17.9928 19.2497 18.2259 19.1531 18.3979 18.9812C18.5698 18.8093 18.6663 18.5761 18.6663 18.333V5.49967C18.6663 5.25656 18.5698 5.0234 18.3979 4.85149Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7497 4.58301C17.9928 4.58301 18.2259 4.67959 18.3979 4.85149C18.5698 5.0234 18.6663 5.25656 18.6663 5.49967V13.7497H0.333008V5.49967C0.333008 5.25656 0.429585 5.0234 0.601493 4.85149C0.773402 4.67959 1.00656 4.58301 1.24967 4.58301H17.7497ZM8.58301 11.9163V10.083H10.4163V11.9163H8.58301Z"
            fill="url(#paint0_linear_198_2787)"
          />
          <path
            d="M4.9165 1.83366V4.58366H6.74984V2.75033H12.2498V4.58366H14.0832V1.83366C14.0832 1.59054 13.9866 1.35739 13.8147 1.18548C13.6428 1.01357 13.4096 0.916992 13.1665 0.916992H5.83317C5.59006 0.916992 5.3569 1.01357 5.18499 1.18548C5.01308 1.35739 4.9165 1.59054 4.9165 1.83366Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_198_2787"
              x1="18.6204"
              y1="9.55918"
              x2="0.0922922"
              y2="9.55918"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.3" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
          </defs>
        </svg>
      );
    }
  }

  const upgradeRef = useRef(null);
  const upgradeBgRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const popup = document.querySelector(".popup__upgrade-subscription");
      if (
        upgradeBgRef.current.contains(event.target) &&
        !upgradeRef.current.contains(event.target)
      ) {
        popup.style.opacity = "0";
        document.body.style.overflow = "auto";
        setTimeout(() => {
          popup.style.display = "none";
        }, 300);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="auth__login app">
      <h1 className="auth__login-h1">Our Pricing</h1>
      <div className="tabs-container app-p">
        <div className="tabs-header">
          <button
            className={activeTab === 0 ? "active" : ""}
            onClick={() => setActiveTab(0)}
          >
            Annually<span>-20% OFF</span>
          </button>
          <button
            className={activeTab === 1 ? "active" : ""}
            onClick={() => setActiveTab(1)}
          >
            Monthly
          </button>
        </div>
        <div className="tabs-content app">
          <div className={`tab-panel ${activeTab === 0 ? "active" : ""} app`}>
            <div
              className={`pay-annuallyC ${
                stateUser.plan === "Basic" && stateUser.year ? "active" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="35"
                viewBox="0 0 29 29"
                fill="none"
              >
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint0_linear_2191_5765)"
                />
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint1_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint2_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint3_linear_2191_5765)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name">Basic</h3>
              <p className="pay-describution">
                For Individuals and <br />
                Business Owners{" "}
              </p>

              <p className="pay-price">
                <span className="dolar">{currency}</span>{" "}
                {priceBasicAnnually[0]}
                <span className="ninety-nine">{priceBasicAnnually[1]}</span>
                <span className="savings">
                  ≈ {currency}
                  {priceBasicAnnually[2]} <span>/ credit</span>
                </span>
              </p>
              <p className="month">
                / Month<span>, Billed Annually</span>
              </p>
              <div className="grid-container app">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">100 Credits per Month</p>
                <p className="Logotypes">400 Logotypes</p>
                <i className="ri-information-line"></i>
                <button
                  onClick={() => toggleUpgradeSubscription("Basic", true)}
                  className={
                    stateUser.plan === "Basic" && stateUser.year
                      ? currentPlanStatus === "cancel"
                        ? "new-plan-button"
                        : "current-plan-button"
                      : "new-plan-button"
                  }
                >
                  {stateUser.plan === "Basic"
                    ? `${
                        stateUser.year
                          ? currentPlanStatus === "cancel"
                            ? "Renew Plan"
                            : "Current Plan"
                          : "Switch the Plan"
                      }`
                    : "Downgrade to Basic"}
                </button>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
            <div
              className={`pay-annuallyB ${
                stateUser.plan === "Pro" && stateUser.year ? "active" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="27"
                viewBox="0 0 28 27"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M26.9422 6.05654C26.6921 5.80649 26.353 5.66602 25.9993 5.66602H1.99935C1.64573 5.66602 1.30659 5.80649 1.05654 6.05654C0.806491 6.30659 0.666016 6.64573 0.666016 6.99935V25.666C0.666016 26.0196 0.806491 26.3588 1.05654 26.6088C1.30659 26.8589 1.64573 26.9993 1.99935 26.9993H25.9993C26.353 26.9993 26.6921 26.8589 26.9422 26.6088C27.1922 26.3588 27.3327 26.0196 27.3327 25.666V6.99935C27.3327 6.64573 27.1922 6.30659 26.9422 6.05654Z"
                  fill="url(#paint0_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint1_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint2_linear_2202_10001)"
                />
                <path
                  d="M7.33203 1.66732V5.66732H9.9987V3.00065H17.9987V5.66732H20.6654V1.66732C20.6654 1.3137 20.5249 0.974557 20.2748 0.724509C20.0248 0.47446 19.6857 0.333984 19.332 0.333984H8.66536C8.31174 0.333984 7.9726 0.47446 7.72256 0.724509C7.47251 0.974557 7.33203 1.3137 7.33203 1.66732Z"
                  fill="url(#paint3_linear_2202_10001)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="26.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="18.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2202_10001"
                    x1="27.2659"
                    y1="12.9041"
                    x2="0.315884"
                    y2="12.9041"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2202_10001"
                    x1="13.9987"
                    y1="0.333984"
                    x2="13.9987"
                    y2="5.66732"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name top">Pro</h3>
              <p className="pay-describution">
                Best for Freelances,
                <br />
                Studios and Agencies
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceProAnnually[0]}
                <span className="ninety-nine">{priceProAnnually[1]}</span>
              </p>
              <p className="month">/ Month, Billed Annually</p>
              <div className="grid-container app">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">Unlimited Credits per Month</p>
                <p className="Logotypes">Unlimited Logotypes</p>
                <i className="ri-information-line"></i>
                <button
                  onClick={() => toggleUpgradeSubscription("Pro", true)}
                  className={
                    stateUser.plan === "Pro" && stateUser.year
                      ? currentPlanStatus === "cancel"
                        ? "new-plan-button"
                        : "current-plan-button"
                      : "new-plan-button"
                  }
                >
                  {stateUser.plan === "Pro"
                    ? `${
                        stateUser.year
                          ? currentPlanStatus === "cancel"
                            ? "Renew Plan"
                            : "Current Plan"
                          : "Switch the Plan"
                      }`
                    : "Upgrade to Pro"}
                </button>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
          </div>
          <div className={`tab-panel ${activeTab === 1 ? "active" : ""} app`}>
            <div
              className={`pay-annuallyC ${
                stateUser.plan === "Basic" && !stateUser.year ? "active" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="29"
                height="35"
                viewBox="0 0 29 29"
                fill="none"
              >
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint0_linear_2191_5765)"
                />
                <path
                  d="M8.43369 24.5916C7.74037 26.9693 7.3937 28.1582 6.72699 28.1582C6.06028 28.1582 5.71361 26.9693 5.02029 24.5916L4.88233 24.1185L4.40922 23.9806C2.03151 23.2872 0.842651 22.9406 0.842651 22.2739C0.842651 21.6072 2.03151 21.2605 4.40922 20.5672L4.88233 20.4292L5.02029 19.9561C5.71361 17.5784 6.06028 16.3895 6.72699 16.3895C7.3937 16.3895 7.74037 17.5784 8.43369 19.9561L8.57165 20.4292L9.04476 20.5672C11.4225 21.2605 12.6113 21.6072 12.6113 22.2739C12.6113 22.9406 11.4225 23.2872 9.04476 23.9806L8.57165 24.1185L8.43369 24.5916Z"
                  fill="url(#paint1_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint2_linear_2191_5765)"
                />
                <path
                  d="M20.5486 16.5779C19.5087 20.1445 18.9887 21.9277 17.9886 21.9277C16.9885 21.9277 16.4685 20.1445 15.4285 16.5779L14.7499 14.2507L12.4227 13.5721C8.85618 12.5321 7.0729 12.0121 7.0729 11.012C7.0729 10.012 8.85618 9.49197 12.4227 8.45198L14.7499 7.77339L15.4285 5.44618C16.4685 1.87962 16.9885 0.0963326 17.9886 0.0963326C18.9887 0.0963326 19.5087 1.87962 20.5486 5.44618L21.2272 7.77339L23.5544 8.45198C27.121 9.49197 28.9043 10.012 28.9043 11.012C28.9043 12.0121 27.121 12.5321 23.5544 13.5721L21.2272 14.2507L20.5486 16.5779Z"
                  fill="url(#paint3_linear_2191_5765)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2191_5765"
                    x1="6.72699"
                    y1="28.1582"
                    x2="6.72699"
                    y2="16.3895"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2191_5765"
                    x1="17.9886"
                    y1="21.9277"
                    x2="17.9886"
                    y2="0.0963326"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name">Basic</h3>

              <p className="pay-describution">
                For Individuals and <br />
                Business Owners
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceBasicMonthly[0]}
                <span className="ninety-nine">{priceBasicMonthly[1]}</span>
                <span className="savings">
                  ≈ {currency}
                  {priceBasicMonthly[2]} <span>/ credit</span>
                </span>
              </p>
              <p className="month">
                / Month<span>, Billed Monthly</span>
              </p>
              <div className="grid-container app">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">100 Credits per Month</p>
                <p className="Logotypes">400 Logotypes</p>
                <i className="ri-information-line"></i>
                <button
                  onClick={() => toggleUpgradeSubscription("Basic", false)}
                  className={
                    stateUser.plan === "Basic" && !stateUser.year
                      ? currentPlanStatus === "cancel"
                        ? "new-plan-button"
                        : "current-plan-button"
                      : "new-plan-button"
                  }
                >
                  {stateUser.plan === "Basic"
                    ? `${
                        !stateUser.year
                          ? currentPlanStatus === "cancel"
                            ? "Renew Plan"
                            : "Current Plan"
                          : "Switch the Plan"
                      }`
                    : "Downgrade to Basic"}
                </button>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
            <div
              className={`pay-annuallyB ${
                stateUser.plan === "Pro" && !stateUser.year ? "active" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="27"
                viewBox="0 0 28 27"
                fill="none"
              >
                <path
                  opacity="0.2"
                  d="M26.9422 6.05654C26.6921 5.80649 26.353 5.66602 25.9993 5.66602H1.99935C1.64573 5.66602 1.30659 5.80649 1.05654 6.05654C0.806491 6.30659 0.666016 6.64573 0.666016 6.99935V25.666C0.666016 26.0196 0.806491 26.3588 1.05654 26.6088C1.30659 26.8589 1.64573 26.9993 1.99935 26.9993H25.9993C26.353 26.9993 26.6921 26.8589 26.9422 26.6088C27.1922 26.3588 27.3327 26.0196 27.3327 25.666V6.99935C27.3327 6.64573 27.1922 6.30659 26.9422 6.05654Z"
                  fill="url(#paint0_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint1_linear_2202_10001)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.9993 5.66602C26.353 5.66602 26.6921 5.80649 26.9422 6.05654C27.1922 6.30659 27.3327 6.64573 27.3327 6.99935V18.9993H0.666016V6.99935C0.666016 6.64573 0.806491 6.30659 1.05654 6.05654C1.30659 5.80649 1.64573 5.66602 1.99935 5.66602H25.9993ZM12.666 16.3327V13.666H15.3327V16.3327H12.666Z"
                  fill="url(#paint2_linear_2202_10001)"
                />
                <path
                  d="M7.33203 1.66732V5.66732H9.9987V3.00065H17.9987V5.66732H20.6654V1.66732C20.6654 1.3137 20.5249 0.974557 20.2748 0.724509C20.0248 0.47446 19.6857 0.333984 19.332 0.333984H8.66536C8.31174 0.333984 7.9726 0.47446 7.72256 0.724509C7.47251 0.974557 7.33203 1.3137 7.33203 1.66732Z"
                  fill="url(#paint3_linear_2202_10001)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="26.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_2202_10001"
                    x1="13.9993"
                    y1="5.66602"
                    x2="13.9993"
                    y2="18.9993"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_2202_10001"
                    x1="27.2659"
                    y1="12.9041"
                    x2="0.315884"
                    y2="12.9041"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.6" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_2202_10001"
                    x1="13.9987"
                    y1="0.333984"
                    x2="13.9987"
                    y2="5.66732"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#415EED" />
                    <stop offset="1" stopColor="#3651DA" />
                  </linearGradient>
                </defs>
              </svg>
              <h3 className="pay-name top">Pro</h3>
              <p className="pay-describution">
                Best for Freelances, <br />
                Studios and Agencies
              </p>
              <p className="pay-price">
                <span className="dolar">{currency}</span> {priceProMonthly[0]}
                <span className="ninety-nine">{priceProMonthly[1]}</span>
              </p>
              <p className="month">/ Month, Billed Monthly</p>
              <div className="grid-container app">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint0_linear_2140_13932)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 22.5C6.477 22.5 2 18.023 2 12.5C2 6.977 6.477 2.5 12 2.5C17.523 2.5 22 6.977 22 12.5C22 18.023 17.523 22.5 12 22.5ZM12 6.62271C11.6816 6.62271 11.4857 7.0425 11.0938 7.88208L10.0665 10.0833C9.99044 10.2463 9.95239 10.3278 9.89011 10.3901C9.82783 10.4524 9.74631 10.4904 9.58327 10.5665L7.38208 11.5938C6.5425 11.9857 6.12271 12.1816 6.12271 12.5C6.12271 12.8184 6.5425 13.0143 7.38208 13.4062L9.58327 14.4335C9.74631 14.5096 9.82783 14.5476 9.89011 14.6099C9.95239 14.6722 9.99044 14.7537 10.0665 14.9167L11.0938 17.1179C11.4857 17.9575 11.6816 18.3773 12 18.3773C12.3184 18.3773 12.5143 17.9575 12.9062 17.1179L13.9335 14.9167C14.0096 14.7537 14.0476 14.6722 14.1099 14.6099C14.1722 14.5476 14.2537 14.5096 14.4167 14.4335L16.6179 13.4062C17.4575 13.0143 17.8773 12.8184 17.8773 12.5C17.8773 12.1816 17.4575 11.9857 16.6179 11.5938L14.4167 10.5665C14.2537 10.4904 14.1722 10.4524 14.1099 10.3901C14.0476 10.3278 14.0096 10.2463 13.9335 10.0833L12.9062 7.88208C12.5143 7.0425 12.3184 6.62271 12 6.62271Z"
                    fill="url(#paint1_linear_2140_13932)"
                    fillOpacity="0.8"
                    style={{ mixBlendMode: "soft-light" }}
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_2140_13932"
                      x1="12"
                      y1="2.5"
                      x2="12"
                      y2="22.5"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#415EED" />
                      <stop offset="1" stopColor="#3651DA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_2140_13932"
                      x1="2"
                      y1="22.5"
                      x2="22.5286"
                      y2="21.9418"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F7F8FC" stopOpacity="0" />
                      <stop offset="0.494792" stopColor="#F7F8FC" />
                      <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="credits-per-month">Unlimited Credits per Month</p>
                <p className="Logotypes">Unlimited Logotypes</p>
                <i className="ri-information-line"></i>
                <button
                  onClick={() => toggleUpgradeSubscription("Pro", false)}
                  className={
                    stateUser.plan === "Pro" && !stateUser.year
                      ? currentPlanStatus === "cancel"
                        ? "new-plan-button"
                        : "current-plan-button"
                      : "new-plan-button"
                  }
                >
                  {stateUser.plan === "Pro"
                    ? `${
                        !stateUser.year
                          ? currentPlanStatus === "cancel"
                            ? "Renew Plan"
                            : "Current Plan"
                          : "Switch the Plan"
                      }`
                    : "Upgrade to Pro"}
                </button>
              </div>
              <div className="inf-popup">
                One credit equals to 4 logotypes per generation
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={upgradeBgRef}
        className="popup__upgrade-subscription"
        style={{ style: "opacity: 0", display: "none" }}
      >
        <div ref={upgradeRef} className="popup__upgrade-subscription-window">
          <div className="popup__upgrade-subscription-window-top">
            <p>
              {stateUser.plan === clickPlan[0]
                ? `${
                    stateUser.year === clickPlan[1] ? `---` : `Switch Your Plan`
                  }`
                : `${
                    clickPlan[0] === "Basic"
                      ? "Downgrade Plan"
                      : "Upgrade Your Plan"
                  }`}
            </p>
            <button onClick={() => toggleUpgradeSubscription(0, false)}>
              <i className="ri-close-line"></i>
            </button>
          </div>
          <div className="popup__upgrade-subscription-window-mid">
            {stateUser.plan === "Basic" ? (
              <div className="current-plan">
                <div className="current-plan-top">
                  <p className="plan">Current plan</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M6.07478 18.9432C5.55479 20.7265 5.29479 21.6182 4.79475 21.6182C4.29472 21.6182 4.03472 20.7265 3.51473 18.9432L3.41126 18.5884L3.05643 18.4849C1.27314 17.9649 0.3815 17.7049 0.3815 17.2049C0.3815 16.7049 1.27314 16.4449 3.05643 15.9249L3.41126 15.8214L3.51473 15.4666C4.03472 13.6833 4.29472 12.7917 4.79475 12.7917C5.29479 12.7917 5.55479 13.6833 6.07478 15.4666L6.17825 15.8214L6.53308 15.9249C8.31637 16.4449 9.20801 16.7049 9.20801 17.2049C9.20801 17.7049 8.31637 17.9649 6.53308 18.4849L6.17825 18.5884L6.07478 18.9432Z"
                      fill="url(#paint0_linear_198_13514)"
                    />
                    <path
                      d="M6.07478 18.9432C5.55479 20.7265 5.29479 21.6182 4.79475 21.6182C4.29472 21.6182 4.03472 20.7265 3.51473 18.9432L3.41126 18.5884L3.05643 18.4849C1.27314 17.9649 0.3815 17.7049 0.3815 17.2049C0.3815 16.7049 1.27314 16.4449 3.05643 15.9249L3.41126 15.8214L3.51473 15.4666C4.03472 13.6833 4.29472 12.7917 4.79475 12.7917C5.29479 12.7917 5.55479 13.6833 6.07478 15.4666L6.17825 15.8214L6.53308 15.9249C8.31637 16.4449 9.20801 16.7049 9.20801 17.2049C9.20801 17.7049 8.31637 17.9649 6.53308 18.4849L6.17825 18.5884L6.07478 18.9432Z"
                      fill="url(#paint1_linear_198_13514)"
                    />
                    <path
                      d="M15.161 12.9329C14.381 15.6078 13.991 16.9453 13.241 16.9453C12.4909 16.9453 12.1009 15.6079 11.3209 12.9329L10.812 11.1875L9.06657 10.6786C6.39165 9.89858 5.05418 9.50859 5.05418 8.75854C5.05418 8.00848 6.39165 7.61849 9.06657 6.8385L10.812 6.32955L11.3209 4.58415C12.1009 1.90922 12.4909 0.571762 13.241 0.571762C13.991 0.571762 14.381 1.90922 15.161 4.58415L15.6699 6.32955L17.4153 6.8385C20.0903 7.61849 21.4277 8.00848 21.4277 8.75854C21.4277 9.50859 20.0903 9.89858 17.4153 10.6786L15.6699 11.1875L15.161 12.9329Z"
                      fill="url(#paint2_linear_198_13514)"
                    />
                    <path
                      d="M15.161 12.9329C14.381 15.6078 13.991 16.9453 13.241 16.9453C12.4909 16.9453 12.1009 15.6079 11.3209 12.9329L10.812 11.1875L9.06657 10.6786C6.39165 9.89858 5.05418 9.50859 5.05418 8.75854C5.05418 8.00848 6.39165 7.61849 9.06657 6.8385L10.812 6.32955L11.3209 4.58415C12.1009 1.90922 12.4909 0.571762 13.241 0.571762C13.991 0.571762 14.381 1.90922 15.161 4.58415L15.6699 6.32955L17.4153 6.8385C20.0903 7.61849 21.4277 8.00848 21.4277 8.75854C21.4277 9.50859 20.0903 9.89858 17.4153 10.6786L15.6699 11.1875L15.161 12.9329Z"
                      fill="url(#paint3_linear_198_13514)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_198_13514"
                        x1="4.79475"
                        y1="21.6182"
                        x2="4.79475"
                        y2="12.7917"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#415EED" />
                        <stop offset="1" stopColor="#3651DA" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_198_13514"
                        x1="4.79475"
                        y1="21.6182"
                        x2="4.79475"
                        y2="12.7917"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" stopOpacity="0.6" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_198_13514"
                        x1="13.241"
                        y1="16.9453"
                        x2="13.241"
                        y2="0.571762"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#415EED" />
                        <stop offset="1" stopColor="#3651DA" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_198_13514"
                        x1="13.241"
                        y1="16.9453"
                        x2="13.241"
                        y2="0.571762"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" stopOpacity="0.3" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <h3>Basic</h3>
                  <p className="description">
                    For Individuals and Business Owners
                  </p>
                </div>
                <div className="line-h"></div>

                <div className="current-plan-bot">
                  <p className="credits-mo">
                    Credits / mo.{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                          fill="url(#paint0_linear_190_24261)"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                          fill="url(#paint1_linear_190_24261)"
                          fillOpacity="0.8"
                          style={{ mixBlendMode: "soft-light" }}
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_190_24261"
                            x1="9"
                            y1="1.5"
                            x2="9"
                            y2="16.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#415EED" />
                            <stop offset="1" stopColor="#3651DA" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_190_24261"
                            x1="1.5"
                            y1="16.5"
                            x2="16.8965"
                            y2="16.0814"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#F7F8FC" stopOpacity="0" />
                            <stop offset="0.494792" stopColor="#F7F8FC" />
                            <stop
                              offset="1"
                              stopColor="#F7F8FC"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      100
                    </span>
                  </p>
                  <p className="credits-mo">
                    Cost / mo.
                    <span>
                      <span>{currency}</span>
                      {stateUser.year
                        ? `${priceBasicAnnually[0]}${priceBasicAnnually[1]}`
                        : `${priceBasicMonthly[0]}${priceBasicMonthly[1]}`}
                    </span>
                  </p>
                  <p className="credits-mo">
                    Cost / credit
                    <span>
                      <span>{currency}</span>
                      {stateUser.year ? `${priceBasicAnnually[2]}` : `${priceBasicMonthly[2]}`}
                    </span>
                  </p>
                  <p className="credits-mo billed">
                    Billed<span>{stateUser.year ? "Annually" : "Monthly"}</span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="current-plan">
                <div className="current-plan-top">
                  <p className="plan blue">Current plan</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      opacity="0.2"
                      d="M19.7071 4.29192C19.5196 4.10438 19.2652 3.99902 19 3.99902H1C0.734784 3.99902 0.48043 4.10438 0.292893 4.29192C0.105357 4.47945 0 4.73381 0 4.99902V18.999C0 19.2642 0.105357 19.5186 0.292893 19.7061C0.48043 19.8937 0.734784 19.999 1 19.999H19C19.2652 19.999 19.5196 19.8937 19.7071 19.7061C19.8946 19.5186 20 19.2642 20 18.999V4.99902C20 4.73381 19.8946 4.47945 19.7071 4.29192Z"
                      fill="url(#paint0_linear_198_13362)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19 3.99902C19.2652 3.99902 19.5196 4.10438 19.7071 4.29192C19.8946 4.47945 20 4.73381 20 4.99902V13.999H0V4.99902C0 4.73381 0.105357 4.47945 0.292893 4.29192C0.48043 4.10438 0.734784 3.99902 1 3.99902H19ZM9 11.999V9.99902H11V11.999H9Z"
                      fill="url(#paint1_linear_198_13362)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19 3.99902C19.2652 3.99902 19.5196 4.10438 19.7071 4.29192C19.8946 4.47945 20 4.73381 20 4.99902V13.999H0V4.99902C0 4.73381 0.105357 4.47945 0.292893 4.29192C0.48043 4.10438 0.734784 3.99902 1 3.99902H19ZM9 11.999V9.99902H11V11.999H9Z"
                      fill="url(#paint2_linear_198_13362)"
                    />
                    <path
                      d="M5 1V4H7V2H13V4H15V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0H6C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1Z"
                      fill="url(#paint3_linear_198_13362)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_198_13362"
                        x1="10"
                        y1="3.99902"
                        x2="10"
                        y2="19.999"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#415EED" />
                        <stop offset="1" stopColor="#3651DA" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_198_13362"
                        x1="10"
                        y1="3.99902"
                        x2="10"
                        y2="13.999"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#415EED" />
                        <stop offset="1" stopColor="#3651DA" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_198_13362"
                        x1="19.9499"
                        y1="9.42758"
                        x2="-0.262599"
                        y2="9.42758"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" stopOpacity="0" />
                        <stop offset="1" stopColor="white" stopOpacity="0.6" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_198_13362"
                        x1="10"
                        y1="0"
                        x2="10"
                        y2="4"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#415EED" />
                        <stop offset="1" stopColor="#3651DA" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <h3>Pro</h3>
                  <p className="description">
                    Best for Freelances, Studios and Agencies
                  </p>
                </div>
                <div className="line-h"></div>
                <div className="current-plan-bot">
                  <p className="credits-mo">
                    Credits / mo.{" "}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                          fill="url(#paint0_linear_190_24261)"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                          fill="url(#paint1_linear_190_24261)"
                          fillOpacity="0.8"
                          style={{ mixBlendMode: "soft-light" }}
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_190_24261"
                            x1="9"
                            y1="1.5"
                            x2="9"
                            y2="16.5"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#415EED" />
                            <stop offset="1" stopColor="#3651DA" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_190_24261"
                            x1="1.5"
                            y1="16.5"
                            x2="16.8965"
                            y2="16.0814"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#F7F8FC" stopOpacity="0" />
                            <stop offset="0.494792" stopColor="#F7F8FC" />
                            <stop
                              offset="1"
                              stopColor="#F7F8FC"
                              stopOpacity="0"
                            />
                          </linearGradient>
                        </defs>
                      </svg>
                      Unlimited
                    </span>
                  </p>
                  <p className="credits-mo">
                    Cost / mo.
                    <span>
                      <span>{currency}</span>
                      {stateUser.year
                        ? `${priceProAnnually[0]}${priceProAnnually[1]}`
                        : `${priceProMonthly[0]}${priceProMonthly[1]}`}
                    </span>
                  </p>
                  <p className="credits-mo billed">
                    Billed<span>{stateUser.year ? "Annually" : "Monthly"}</span>
                  </p>
                </div>
              </div>
            )}
            <div
              className={`decorativ-arrow ${
                stateUser.plan === "Basic" ? "" : "downgrade"
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="426"
                viewBox="0 0 2 426"
                fill="none"
              >
                <path
                  d="M1 0L1.00002 426"
                  stroke="url(#paint0_linear_190_24242)"
                  strokeOpacity="0.12"
                  strokeWidth="1.2"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_190_24242"
                    x1="1"
                    y1="426"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#0F1B5E" stopOpacity="0" />
                    <stop offset="0.499997" stopColor="#0F1B5E" />
                    <stop offset="1" stopColor="#0F1B5E" stopOpacity="0" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="arrow">
                <i class="ri-arrow-right-fill"></i>
              </div>
            </div>
            <CardPlan />
          </div>
          <div
            className={`popup__upgrade-subscription-window-bot ${
              stateUser.plan === "Basic"
                ? `${
                    stateUser.year !== clickPlan[1]
                      ? `${clickPlan[0] === "Basic" ? "downgrade" : ""}`
                      : ""
                  }`
                : `${
                    stateUser.year !== clickPlan[1]
                      ? `${clickPlan[0] === "Basic" ? "downgrade" : ""}`
                      : `downgrade`
                  }`
            }`}
          >
            <button onClick={() => validOpen()}>
              <SvgPlan />
              <p>
                {stateUser.plan === clickPlan[0]
                  ? `${
                      stateUser.year === clickPlan[1]
                        ? `---`
                        : `Switch Your Plan`
                    }`
                  : `${
                      clickPlan[0] === "Basic"
                        ? "Downgrade to Basic"
                        : "Upgrade to Pro"
                    }`}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppPricing;
