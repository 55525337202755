function CanselPlan({ pricing }) {
  return (
    <div className="plan-billing__wrapper-manage">
      <p className="plan-billing__wrapper-manage-your">Your plan:</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.6665 15.9998C2.6665 23.3636 8.63604 29.3332 15.9998 29.3332C23.3636 29.3332 29.3332 23.3636 29.3332 15.9998C29.3332 8.63604 23.3636 2.6665 15.9998 2.6665C8.63604 2.6665 2.6665 8.63604 2.6665 15.9998ZM15.9998 16.5033C19.6817 16.5033 22.6665 19.4881 22.6665 23.17H19.9998C19.9998 20.9608 18.209 19.17 15.9998 19.17C13.7906 19.17 11.9998 20.9608 11.9998 23.17L9.33317 23.17C9.33317 19.4881 12.318 16.5033 15.9998 16.5033ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
          fill="#757A90"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.6665 15.9998C2.6665 23.3636 8.63604 29.3332 15.9998 29.3332C23.3636 29.3332 29.3332 23.3636 29.3332 15.9998C29.3332 8.63604 23.3636 2.6665 15.9998 2.6665C8.63604 2.6665 2.6665 8.63604 2.6665 15.9998ZM15.9998 16.5033C19.6817 16.5033 22.6665 19.4881 22.6665 23.17H19.9998C19.9998 20.9608 18.209 19.17 15.9998 19.17C13.7906 19.17 11.9998 20.9608 11.9998 23.17L9.33317 23.17C9.33317 19.4881 12.318 16.5033 15.9998 16.5033ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
          fill="url(#paint0_linear_95_22890)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.6665 15.9998C2.6665 23.3636 8.63604 29.3332 15.9998 29.3332C23.3636 29.3332 29.3332 23.3636 29.3332 15.9998C29.3332 8.63604 23.3636 2.6665 15.9998 2.6665C8.63604 2.6665 2.6665 8.63604 2.6665 15.9998ZM15.9998 16.5033C19.6817 16.5033 22.6665 19.4881 22.6665 23.17H19.9998C19.9998 20.9608 18.209 19.17 15.9998 19.17C13.7906 19.17 11.9998 20.9608 11.9998 23.17L9.33317 23.17C9.33317 19.4881 12.318 16.5033 15.9998 16.5033ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
          fill="url(#paint1_linear_95_22890)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_95_22890"
            x1="15.9998"
            y1="2.6665"
            x2="15.9998"
            y2="29.3332"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#415EED" />
            <stop offset="1" stopColor="#3651DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_95_22890"
            x1="15.9998"
            y1="2.6665"
            x2="15.9998"
            y2="29.3332"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" stopOpacity="0.3" />
          </linearGradient>
        </defs>
      </svg>
      <p className="plan-billing__wrapper-manage-plan">
        You have no subscription yet
      </p>
      <p className="plan-billing__wrapper-manage-description cansel">
        To use LogoliveryAI, you should choose one of the plans and subscribe to
        it.
      </p>

      <button className="button-manage__buy" onClick={pricing}>
        <p>View Plans</p>
      </button>
    </div>
  );
}

export default CanselPlan;
