  // Ініціалізує dataLayer
const dataLayer = window.dataLayer || [];


const useGoogleAnalytics = (gaMeasurementId) => {

  window.dataLayer = window.dataLayer || [];

  // Функція gtag
  function gtag() {
    window.dataLayer.push(arguments);
  }
  // Перевіряє, чи вже існує скрипт gtag
  if (!window.gtag) {
    window.gtag = gtag;
    gtag("js", new Date());
    gtag("config", gaMeasurementId);

    // Додає скрипт gtag до документа
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`;
    document.head.appendChild(script);
  }
};

const trackPlanEvent = (planType) => {

  if (planType === "month") {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "plan_selection_monthly", // це назва події, яка повинна співпадати з умовою тригера
      plan_type: "Monthly", // це значення змінної, яка також використовується в умові тригера
    });
  } else if (planType === "year") {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "plan_selection_annually",
      plan_type: "Annually",
    });
  }
};

export { useGoogleAnalytics, trackPlanEvent };
