function BusinessPlan({
  popup,
  currentPlan,
  toggleUpgradeSubscription,
  currency,
  price,
}) {
  return (
    <div className="plan-billing__wrapper-manage">
      {currentPlan === "trialing" && (
        <p className="plan-billing__wrapper-manage-free">
          You are on 3-days free trial
        </p>
      )}
      <p className="plan-billing__wrapper-manage-your">Your plan:</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="27"
        viewBox="0 0 28 27"
        fill="none"
      >
        <path
          opacity="0.2"
          d="M26.9422 6.05849C26.6921 5.80844 26.353 5.66797 25.9993 5.66797H1.99935C1.64573 5.66797 1.30659 5.80844 1.05654 6.05849C0.806491 6.30854 0.666016 6.64768 0.666016 7.0013V25.668C0.666016 26.0216 0.806491 26.3607 1.05654 26.6108C1.30659 26.8608 1.64573 27.0013 1.99935 27.0013H25.9993C26.353 27.0013 26.6921 26.8608 26.9422 26.6108C27.1922 26.3607 27.3327 26.0216 27.3327 25.668V7.0013C27.3327 6.64768 27.1922 6.30854 26.9422 6.05849Z"
          fill="url(#paint0_linear_107_15602)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9993 5.66797C26.353 5.66797 26.6921 5.80844 26.9422 6.05849C27.1922 6.30854 27.3327 6.64768 27.3327 7.0013V19.0013H0.666016V7.0013C0.666016 6.64768 0.806491 6.30854 1.05654 6.05849C1.30659 5.80844 1.64573 5.66797 1.99935 5.66797H25.9993ZM12.666 16.3346V13.668H15.3327V16.3346H12.666Z"
          fill="url(#paint1_linear_107_15602)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.9993 5.66797C26.353 5.66797 26.6921 5.80844 26.9422 6.05849C27.1922 6.30854 27.3327 6.64768 27.3327 7.0013V19.0013H0.666016V7.0013C0.666016 6.64768 0.806491 6.30854 1.05654 6.05849C1.30659 5.80844 1.64573 5.66797 1.99935 5.66797H25.9993ZM12.666 16.3346V13.668H15.3327V16.3346H12.666Z"
          fill="url(#paint2_linear_107_15602)"
        />
        <path
          d="M7.33301 1.66927V5.66927H9.99967V3.0026H17.9997V5.66927H20.6663V1.66927C20.6663 1.31565 20.5259 0.97651 20.2758 0.726462C20.0258 0.476413 19.6866 0.335938 19.333 0.335938H8.66634C8.31272 0.335938 7.97358 0.476413 7.72353 0.726462C7.47348 0.97651 7.33301 1.31565 7.33301 1.66927Z"
          fill="url(#paint3_linear_107_15602)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_107_15602"
            x1="13.9993"
            y1="5.66797"
            x2="13.9993"
            y2="27.0013"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#415EED" />
            <stop offset="1" stopColor="#3651DA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_107_15602"
            x1="13.9993"
            y1="5.66797"
            x2="13.9993"
            y2="19.0013"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#415EED" />
            <stop offset="1" stopColor="#3651DA" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_107_15602"
            x1="27.2659"
            y1="12.906"
            x2="0.315884"
            y2="12.906"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0" />
            <stop offset="1" stopColor="white" stopOpacity="0.6" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_107_15602"
            x1="13.9997"
            y1="0.335937"
            x2="13.9997"
            y2="5.66927"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#415EED" />
            <stop offset="1" stopColor="#3651DA" />
          </linearGradient>
        </defs>
      </svg>
      <p className="plan-billing__wrapper-manage-plan">Pro</p>
      <p className="plan-billing__wrapper-manage-description">
        Best for Freelances, Studios and Agencies
      </p>
      <p className="pay-price app">
        <span className="dolar">{currency}</span> {price[0]}
        <span className="ninety-nine">{price[1]}</span>
      </p>
      <p className="month app">
        <span>/ Month,</span> Billed Annually
      </p>
      <button
        className="button-manage__edit"
        onClick={() => toggleUpgradeSubscription()}
      >
        Downgrade Plan
      </button>
      <button className="button-maname__cancel" onClick={() => popup()}>
        Cancel Subscription
      </button>
    </div>
  );
}

export default BusinessPlan;
