
import { Route, Routes } from "react-router-dom";
import DefaultVersions from "./versionsGenerations/DefaultVersions";
import ProVersions from "./versionsGenerations/ProVersions";

function AppChat({ pricing, setPlaceInLine }) {
  
  return (
    
      <Routes>
          <Route path="/v1" element={<DefaultVersions pricing={pricing}/>} />
          <Route path="/" element={<ProVersions setPlaceInLine={setPlaceInLine} pricing={pricing}/>} />
      </Routes>
    
  );
}

export default AppChat;
