import React, { useEffect, useState } from "react";

const PlaceInLine = ({ uuid }) => {
  const [placeInLine, setPlaceInLine] = useState(0);
  const [containerStyle, setContainerStyle] = useState({ display: "none" });
  // const [top, setTop] = useState(220);

//   const handleScroll = () => { 
//     setTop(top - 5); // Змінюємо позицію елемента залежно від прокрутки
// };
// useEffect(() => {
//   window.addEventListener('scroll', handleScroll);

//   return () => {
//       window.removeEventListener('scroll', handleScroll);
//   };
// }, []);
  useEffect(() => {
    let interval;
    let flag = false;
    const fetchPlaceInLine = () => {
      fetch(`https://queue.logolivery.ai/api/tasks/retrieve`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ uuid }),
      })
        .then((res) => res.json())
        .then((data) => {
          setPlaceInLine(data.position);
          console.log(data);
         
          if (data.position > 0 && flag === false) {
            interval = setInterval(fetchPlaceInLine, 10000);
            setContainerStyle({ display: "block" });
            flag = true;
          } else if (data.position === "0" && flag === true) {
            setContainerStyle({ display: "none" });
            clearInterval(interval);
            flag = false;
          }
        });
    };
    if (uuid) {
      fetchPlaceInLine();
    }
    return () => clearInterval(interval);
  }, [uuid]);

  return (
    <div className="container-place" style={containerStyle}>
      <div className="place-in-line">
        <p className="queue">Your Position in Queue</p>
        <div className="circle">
          <p>{placeInLine}</p>
        </div>
      </div>
    </div>
  );
};

export default PlaceInLine;
