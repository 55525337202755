import { useSelector } from "react-redux";

function ProPlanM({currency, price}) {
  const stateUser = useSelector((state) => state);
  return (
    <div className="current-plan">
      <div className="current-plan-top">
        <p className="plan blue">
          {stateUser.plan === "Pro" ? "SWITCHING TO" : "upgrading to"}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            opacity="0.2"
            d="M19.7071 4.29192C19.5196 4.10438 19.2652 3.99902 19 3.99902H1C0.734784 3.99902 0.48043 4.10438 0.292893 4.29192C0.105357 4.47945 0 4.73381 0 4.99902V18.999C0 19.2642 0.105357 19.5186 0.292893 19.7061C0.48043 19.8937 0.734784 19.999 1 19.999H19C19.2652 19.999 19.5196 19.8937 19.7071 19.7061C19.8946 19.5186 20 19.2642 20 18.999V4.99902C20 4.73381 19.8946 4.47945 19.7071 4.29192Z"
            fill="url(#paint0_linear_198_13362)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 3.99902C19.2652 3.99902 19.5196 4.10438 19.7071 4.29192C19.8946 4.47945 20 4.73381 20 4.99902V13.999H0V4.99902C0 4.73381 0.105357 4.47945 0.292893 4.29192C0.48043 4.10438 0.734784 3.99902 1 3.99902H19ZM9 11.999V9.99902H11V11.999H9Z"
            fill="url(#paint1_linear_198_13362)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 3.99902C19.2652 3.99902 19.5196 4.10438 19.7071 4.29192C19.8946 4.47945 20 4.73381 20 4.99902V13.999H0V4.99902C0 4.73381 0.105357 4.47945 0.292893 4.29192C0.48043 4.10438 0.734784 3.99902 1 3.99902H19ZM9 11.999V9.99902H11V11.999H9Z"
            fill="url(#paint2_linear_198_13362)"
          />
          <path
            d="M5 1V4H7V2H13V4H15V1C15 0.734784 14.8946 0.48043 14.7071 0.292893C14.5196 0.105357 14.2652 0 14 0H6C5.73478 0 5.48043 0.105357 5.29289 0.292893C5.10536 0.48043 5 0.734784 5 1Z"
            fill="url(#paint3_linear_198_13362)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_198_13362"
              x1="10"
              y1="3.99902"
              x2="10"
              y2="19.999"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#415EED" />
              <stop offset="1" stopColor="#3651DA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_198_13362"
              x1="10"
              y1="3.99902"
              x2="10"
              y2="13.999"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#415EED" />
              <stop offset="1" stopColor="#3651DA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_198_13362"
              x1="19.9499"
              y1="9.42758"
              x2="-0.262599"
              y2="9.42758"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0" />
              <stop offset="1" stopColor="white" stopOpacity="0.6" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_198_13362"
              x1="10"
              y1="0"
              x2="10"
              y2="4"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#415EED" />
              <stop offset="1" stopColor="#3651DA" />
            </linearGradient>
          </defs>
        </svg>
        <h3>Pro</h3>
        <p className="description">Best for Freelances, Studios and Agencies</p>
      </div>
      <div className="line-h"></div>

      <div className="current-plan-bot">
        <p className="credits-mo">
          Credits / mo.{" "}
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                fill="url(#paint0_linear_190_24261)"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 16.5C4.85775 16.5 1.5 13.1423 1.5 9C1.5 4.85775 4.85775 1.5 9 1.5C13.1422 1.5 16.5 4.85775 16.5 9C16.5 13.1423 13.1422 16.5 9 16.5ZM9 4.59203C8.76118 4.59203 8.61425 4.90688 8.32037 5.53656L7.5499 7.18745C7.49283 7.30973 7.46429 7.37087 7.41758 7.41758C7.37087 7.4643 7.30973 7.49283 7.18745 7.5499L5.53656 8.32037C4.90688 8.61425 4.59203 8.76118 4.59203 9C4.59203 9.23882 4.90688 9.38575 5.53656 9.67963L7.18745 10.4501C7.30973 10.5072 7.37087 10.5357 7.41758 10.5824C7.46429 10.6291 7.49283 10.6903 7.5499 10.8125L8.32037 12.4634C8.61425 13.0931 8.76118 13.408 9 13.408C9.23882 13.408 9.38575 13.0931 9.67963 12.4634L10.4501 10.8125C10.5072 10.6903 10.5357 10.6291 10.5824 10.5824C10.6291 10.5357 10.6903 10.5072 10.8125 10.4501L12.4634 9.67963C13.0931 9.38575 13.408 9.23882 13.408 9C13.408 8.76118 13.0931 8.61425 12.4634 8.32037L10.8125 7.5499C10.6903 7.49283 10.6291 7.4643 10.5824 7.41758C10.5357 7.37087 10.5072 7.30973 10.4501 7.18745L9.67963 5.53656C9.38575 4.90688 9.23882 4.59203 9 4.59203Z"
                fill="url(#paint1_linear_190_24261)"
                fillOpacity="0.8"
                style={{ mixBlendMode: "soft-light" }}
              />
              <defs>
                <linearGradient
                  id="paint0_linear_190_24261"
                  x1="9"
                  y1="1.5"
                  x2="9"
                  y2="16.5"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#415EED" />
                  <stop offset="1" stopColor="#3651DA" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_190_24261"
                  x1="1.5"
                  y1="16.5"
                  x2="16.8965"
                  y2="16.0814"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#F7F8FC" stopOpacity="0" />
                  <stop offset="0.494792" stopColor="#F7F8FC" />
                  <stop offset="1" stopColor="#F7F8FC" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
            Unlimited
          </span>
        </p>
        <p className="credits-mo">
          Cost / mo.
          <span>
          <span>{currency}</span>{price[0]}{price[1]}
          </span>
        </p>

        <p className="credits-mo billed">
          Billed<span>Monthly</span>
        </p>
        <p style={{ opacity: "0" }} className="credits-mo">
          Cost / credit
          <span>
            <span>{currency}</span>0
          </span>
        </p>
      </div>
    </div>
  );
}

export default ProPlanM;
