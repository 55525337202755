import React, { useState, useEffect } from "react";

import { endPointAdress } from "../../config";
function ForgotSend({
  switchToLogin,
  inputValue,
  switchToSend,
  switchToForgot,
}) {
 
  const [timer, setTimer] = useState(30);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const handleSubmit = () => {
    setButtonDisabled(true);
    fetch(`${endPointAdress}forgot-pass`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inputValue.email.toLowerCase(),
      }),
    })
      .then((response) => {
        console.log("Status code:", response.status);
        if (response.status === 200) {
          switchToSend();
        } else {
          document.querySelector("#fail1").classList.add("true");
          let inputErrorT = document.querySelector("#nickTrue");
          inputErrorT.classList.add("faili");
          inputErrorT.focus();
          
        }
        return response.json();
      })
      .then((data) => {
        console.log("Response data:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    let timerCount = timer;

    const interval = setInterval(() => {
      timerCount--;
      setTimer(timerCount);
      if (timerCount === 0) clearInterval(interval);
    }, 1000);

    setTimeout(() => {
      setButtonDisabled(false);
      setTimer(30);
      clearInterval(interval);
    }, 30000);
  };

  useEffect(() => {
    document.querySelector("#mainRoot").className = "";
  }, []);
  return (
    <div className="auth__login">
      <img src="\img\png\authImg\Ill.png" alt="" className="email-send" />
      <h1 className="auth__login-h1 forgot">Email sent!</h1>
      <p className="forgot__password">
        We’ve sent restore instructions <br />
        on <span>{inputValue.email}</span>
      </p>

      <div className="container-button">
        <button
          className="change-resend"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? `Resend email ${timer}` : "Resend email"}
        </button>
        <button className="change-resend" onClick={switchToForgot}>
          <p>Change email</p>
        </button>
      </div>

      <button className="auth__forgotUser" onClick={switchToLogin}>
        <i class="ri-close-line"></i>
      </button>
    </div>
  );
}

export default ForgotSend;
