import React, { useEffect } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import CryptoJS from "crypto-js";
import Auth from "./auth";
import AppPage from "./appPage/appMain";
import { useGoogleAnalytics, trackPlanEvent } from "./googleAnalytics";
import { endPointAdress } from "./config";
import reportWebVitals from "./reportWebVitals";
import reducer from "./reducer";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as actions from "./actions";
import Loading from "./loader";


const MainPage = ReactDOM.createRoot(document.getElementById("mainRoot"));
let encryptionSeed = "385h52m27s81ms";
const store = createStore(reducer);
function logout() {
  window.location.reload();
}
// console.error = console.log = console.warn = function () {};
function Renders() {
  const stateUser = useSelector(
    (state) => state.applicationState,
    shallowEqual
  );
  const dispatch = useDispatch();
  const checkAuthTrue = () => dispatch(actions.authTrue());
  const checkAuthFalse = () => dispatch(actions.authFalse());
  const checkPaymentFalse = () => dispatch(actions.paymentFalse());
  const checkEmailFalse = () => dispatch(actions.emailFalse());
  const checkForgot = () => dispatch(actions.forgot());
  const userDataUpdate = () => dispatch(actions.userData());
  const newPlanUpdate = () => dispatch(actions.newPlan());
  const yearPlanUpdate = () => dispatch(actions.yearPlan());
  const yearPlanF = () => dispatch(actions.yearPlanF());
  const gammaRef = localStorage.getItem("gammaRef") ? true : false;
  const betaID = localStorage.getItem("betaID") ? true : false;
  const plan = localStorage.getItem("plan") ? true : false;
  const alphaID = localStorage.getItem("alphaID") ? true : false;
  useGoogleAnalytics('G-251CEVFCHP');


  const Paddle = window.Paddle;
  useEffect(() => {
    // Paddle.Environment.set("sandbox");
    Paddle.Setup({
      // seller: 168428, 11534
      seller: 168428,
      eventCallback: function (data) {
        if (data.name === "checkout.completed") {
          checkoutClosed(data);
        }
        if (data.name === "checkout.closed") {
          closePaddle();
        }
      },
    });
  }, []);

  function closePaddle() {
    document.body.style.overflow = "auto";
  }
  function checkoutClosed(data) {
    document.body.style.overflow = "auto";
    if (data.data.items[0].trial_period) {
      trackPlanEvent(data.data.items[0].billing_cycle.interval);
      let emailUser = "";
      let idUser = "";
      fetch(`${endPointAdress}subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.email && data.id) {
            emailUser = data.email;
            idUser = data.id;
          }
          Paddle.Checkout.close();


        
          dispatch({ type: "LOAD" });
          fetch(`${endPointAdress}is-customer`, {
            method: "POST",
            body: JSON.stringify({
              sid: idUser,
            }),
          })
            .then((response) => {
              if (response.status === 200) {
                checkAuthTrue();
                return response.json();
              }
            })
            .then((data) => {
              localStorage.setItem(
                "gammaRef",
                CryptoJS.AES.encrypt(data.ava, encryptionSeed).toString()
              );
              localStorage.setItem(
                "betaID",
                CryptoJS.AES.encrypt(data.email, encryptionSeed).toString()
              );
              localStorage.setItem("plan", data.plan);
              localStorage.setItem(
                "alphaID",
                CryptoJS.AES.encrypt(data.nickname, encryptionSeed).toString()
              );
              localStorage.setItem("credits", data.credits);
              localStorage.setItem("status", data.status);
              userDataUpdate();
              document.body.style.overflow = "auto";
              dispatch({ type: "STATUS", newStatus: data.status });
              dispatch({ type: "AUTH", userType: data.user_type });
              dispatch({ type: "CREDITS", payload: data.credits });
              callPlanDetails();
              setTimeout(() => {
                dispatch({ type: "CLOSED" });
              }, 2400);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      userDataUpdate();
      document.body.style.overflow = "auto";
      setTimeout(() => {
        callPlanDetails();
        Paddle.Checkout.close();
      }, 3000);
    }
  }
  const callPlanDetails = () => {
    fetch(`${endPointAdress}user/subscription`, {
      method: "POST",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 500) {
        }
      })
      .then((data) => {
        if (data.status === "cancel") {
          const nextBilledAt = new Date(data.scheduled_cancel);
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          dispatch({
            type: "DATE",
            newFormattedDate: `${
              months[nextBilledAt.getMonth()]
            } ${nextBilledAt.getDate()}, ${nextBilledAt.getFullYear()}`,
          });
        } else if (data.status !== "cancel" && data.status !== "canceled") {
          const nextBilledAt = new Date(data.next_billed_at);
          const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          dispatch({
            type: "DATE",
            newFormattedDate: `${
              months[nextBilledAt.getMonth()]
            } ${nextBilledAt.getDate()}, ${nextBilledAt.getFullYear()}`,
          });
        }
        if (data.billing_cycle === "year") {
          yearPlanUpdate();
        } else {
          yearPlanF();
        }
        localStorage.setItem("plan", data.type);
        if (data.type === "Basic" && data.status !== "trialing") {
          localStorage.setItem("credits", 100);
          dispatch({ type: "CREDITS", payload: 100 });
        } else if (data.type === "Pro" && data.status !== "trialing") {
          localStorage.setItem("credits", 500);
          dispatch({ type: "CREDITS", payload: 500 });
        }
        if (data.status !== localStorage.getItem("status")) {
          dispatch({ type: "STATUS", newStatus: data.status });
          localStorage.setItem("status", data.status);
        }
        newPlanUpdate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const attribute = params.get("code");
    const auth = params.get("auth");
    const state = params.get("state");
    const newEmail = params.get("e");
    


    if (!attribute && !auth) {
      if (gammaRef || betaID || plan || alphaID) {
        let flag = true;

        fetch(`${endPointAdress}check`, {


          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic",
          },
          body: JSON.stringify({}),
        })
          .then((response) => {
            if (response.ok) {
              checkAuthTrue();
              userDataUpdate();
              return response.json();
            } else if (response.status === 402) {
              flag = false;
              return response.json();
            } else if (response.status === 412) {
              checkEmailFalse();
            } else {
              checkAuthFalse();
            }
          })
          .then((data) => {
            dispatch({ type: "CURRENCY", payload: data.currency });

            if (flag) {

              localStorage.setItem("credits", data.credits);
              localStorage.setItem("status", data.status);
              dispatch({ type: "CREDITS", payload: data.credits });
              dispatch({ type: "AUTH", userType: data.user_type });
            } else {
              checkPaymentFalse();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      } else if (!gammaRef || !betaID || !plan || !alphaID) {
        let flag = true;
        fetch(`${endPointAdress}check`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic",
            data: "account_info",
          },
          body: JSON.stringify({
            email: `${betaID}`,
            nickname: `${alphaID}`,
            ava: `${gammaRef}`,
            plan: `${plan}`,
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              checkAuthTrue();
              return response.json();
            } else if (response.status === 402) {

              flag = false;
              return response.json();
            } else if (response.status === 412) {
              checkEmailFalse();
            } else {
              checkAuthFalse();
            }
          })
          .then((data) => {
            dispatch({ type: "CURRENCY", payload: data.currency });

            if (flag) {

              localStorage.setItem(
                "gammaRef",
                CryptoJS.AES.encrypt(data.ava, encryptionSeed).toString()
              );
              localStorage.setItem(
                "betaID",
                CryptoJS.AES.encrypt(data.email, encryptionSeed).toString()
              );
              localStorage.setItem("plan", data.plan);
              localStorage.setItem(
                "alphaID",
                CryptoJS.AES.encrypt(data.nickname, encryptionSeed).toString()
              );
              localStorage.setItem("credits", data.credits);
              localStorage.setItem("status", data.status);
              userDataUpdate();
              newPlanUpdate();
              dispatch({ type: "CREDITS", payload: data.credits });
              dispatch({ type: "AUTH", userType: data.user_type });
            } else {
              checkPaymentFalse();
            }

          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } else if (attribute && state) {
      let flag = true;
      fetch(`${endPointAdress}sign-in/${state}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code: attribute,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, "", newUrl);
            checkAuthTrue();
            return response.json();
          } else if (response.status === 412) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, "", newUrl);
            checkEmailFalse();
          } else if (response.status === 402) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, "", newUrl);
            flag = false;
            window.location.reload();
            return response.json();
          } else {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, "", newUrl);
          }
        })
        .then((data) => {
          dispatch({ type: "CURRENCY", payload: data.currency });
          if (flag) {
            localStorage.setItem(
              "gammaRef",
              CryptoJS.AES.encrypt(data.ava, encryptionSeed).toString()
            );
            localStorage.setItem(
              "betaID",
              CryptoJS.AES.encrypt(data.email, encryptionSeed).toString()
            );
            localStorage.setItem("plan", data.plan);
            localStorage.setItem(
              "alphaID",
              CryptoJS.AES.encrypt(data.nickname, encryptionSeed).toString()
            );
            localStorage.setItem("credits", data.credits);
            localStorage.setItem("status", data.status);
            userDataUpdate();
            newPlanUpdate();
            dispatch({ type: "CREDITS", payload: data.credits });
            dispatch({ type: "AUTH", userType: "oauth" });
          } else {
            checkPaymentFalse();
          }

        })
        .catch((error) => {
          console.error("Error:", error);
        });

      //document.querySelector('.auth__main').classList.remove('step4');
    } else if (auth && state) {
      checkForgot();
    }

    if (newEmail) {
      fetch(`${endPointAdress}email-change`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: newEmail,
        }),
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, "", newUrl);

            return response.json();
          } else if (response.status === 412) {
            const newUrl = window.location.pathname;
            window.history.replaceState({}, "", newUrl);
          }
        })
        .then((data) => {
          localStorage.setItem(
            "betaID",
            CryptoJS.AES.encrypt(data.email, encryptionSeed).toString()
          );
          userDataUpdate();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, []);

  if (stateUser === "loading") {
    return <div></div>;
  } else if (stateUser === "logins") {
    return <AppPage render={() => logout()} />;
  } else if (stateUser !== "logins") {
    return <Auth />;
  }
}

MainPage.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <Auth /> */}
      {/* <AppPage /> */}
      <Loading />
      <Renders />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
